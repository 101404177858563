.productosActualizadosList{
    width: 90%;
}

.infoAdicional{
    background-color: white;
    color: black;
    border-radius: 8px;
    width: 800px;
    padding-top: 16px;
    padding-bottom: 8px;
    padding-right: 8px;
    padding-left: 8px;
}

.btnCambioDePrecio{
    background-color: rgba(164,135,251,0.2);
    border-radius: 24px;
    color: #A487FB;
    padding-bottom: 8px;
    padding-top: 8px;
    padding-right: 16px;
    padding-left: 16px;
    pointer-events: none;
}

.btnDisponible{
    background-color: rgba(173,223,135,0.5);
    border-radius: 24px;
    color: #4C9A2A;
    padding-bottom: 8px;
    padding-top: 8px;
    padding-right: 16px;
    padding-left: 16px;
    pointer-events: none;
}

.btnAgotado{
    background-color: rgba(158,233,240,0.5);
    border-radius: 24px;
    color: #3BC2E2;
    padding-bottom: 8px;
    padding-top: 8px;
    padding-right: 16px;
    padding-left: 16px;
    pointer-events: none;
}

.btnNoDisponible{
    background-color: #F698A4;
    border-radius: 24px;
    color: #B12704;
    padding-bottom: 8px;
    padding-top: 8px;
    padding-right: 16px;
    padding-left: 16px;
    pointer-events: none;
}

.search-bar-PA{
    width: 24%;
    margin-top:4%;
    margin-bottom: 4%;
}

.input-search-bar-PA{
    border-radius: 24px;
    height: 48px;
}

.search-bar-button-PA{
    font-size: 24px;
    background-color: transparent;
    border:none;
    color: black
}

@media (max-width: 600px) { 
    .search-bar-PA{
        width: 90%;
    }
}