  .product {
    display: flex;
  }
  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .stripeP p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.154px;
    color: #242d60;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  .stripeImg {
    border-radius: 6px;
    margin: 10px;
    width: 280px;
    height: 300px;
    
  }
  

  h3{
    font-weight: bold;
  }

  .stripeHeadingsStyle h5 {
    opacity: 0.5;
  }
  .checkout-button-kangupago {
    border-radius: 25px;
    height: 40px;
    width: 94%;
    background-color:#00A3D5;
    color:white;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: 700;
    font-size:16px;
  }

  .checkout-button-kangupago:hover {
    background-color:#F5F5F7;
    color:#00A3D5;
    border: 1px solid #00A3D5;
    opacity: 0.8;
  }

  .checkout-button{
    border-radius: 25px;
    height: 40px;
    width: 94%;
    background-color:#E75B10;
    color:white;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: 700;
    font-size:16px;
  }

  .checkout-button:hover {
    background-color:#F5F5F7;
    color:#E75B10;
    border: 1px solid #E75B10;
    opacity: 0.8;
  }

  .checkout-button-agotado{
    border-radius: 25px;
    height: 40px;
    width: 100%;
    background-color:#9B9B9B;
    color:white;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: 700;
    font-size:16px;
    pointer-events: none;
  }
  

  @media (max-width: 500px) {
    .stripeImg{
        width: 100%;
        height: 100%;
        text-align: center;
    }
    .product{
      display:block;
    }
    .checkout-button{
      height: 50px;
    }
    .checkout-button-kangupago{
      height: 50px;
    }
  }

  .sub-title{
    font-weight: 700;
    font-family: 'Atkinson Hyperlegible', sans-serif;
  }
  