.no-display-mobile{
    display: inline;
}

.no-display-md{
    display: inline;
}

.btn-cancel{
    background-color: white;
    border: 1px solid black;
    color: black;
    font-size: 14px;
}

.btn-cancel:hover{
    background-color: #e40b00;
    color: white;
    border: 1px solid #e40b00;
}

@media(max-width: 600px){
    .no-display-mobile{
        display: none;
    }
}

@media(min-width: 600px){
    .no-display-md{
        display: none;
    }
}