.categorias{
    padding-bottom:32%;
}

.card.categoriasMobile{
    width: 100%;
    height: 8rem;
    background-color: white;
    border: 1px solid #E3E0DD;
}

.category-card-img{
    height: 5.5rem;
}

.categoria-card-body{
    margin-top: -8px;
}

.categoria-card-text{
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.ver-mas-parent-tiendas{
    margin-top:24px;
    text-align: center;
}

.btn-filtro{
    color:black;
    /* background-color: #E75B10; */
    background-color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    padding-left:16px;
    padding-right:16px;
    padding-top:12px;
    padding-bottom:12px;
    border-radius: 12px;
    border: 2px solid black;
}

.btn-filtro:hover{
    color:black;
}