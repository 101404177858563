
.mensaje-container{
    margin-bottom: 8px;
}

.text{
    max-width: 42%;
    border-radius: 8px;
    font-size: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 36px;
    margin-bottom: 0px;
}

.receiver-text{
    color: black;
    background-color: #F5F5F7;
}

.sender-text{
    color: white;
    background-color: #E75B10;
    margin-left: auto;
    right: 0px;
}

@media (max-width: 600px){
    .text{
        max-width: 80%;
        border-radius: 8px;
        font-size: 16px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 8px;
        padding-right: 36px;
        margin-bottom: 4px;
    }
    .receiver-text{
        color: black;
        background-color: white;
    }
}