
.chat-side-menu{
    height: 600px;
    background-color:white;
    overflow: scroll;
}

.chat{
    height: 600px;
    background-color:white;
    border-left: 1px solid #E3E0DD;
    position: relative;
    margin-bottom: 8%;
}

.chats-title{
    margin-bottom: 0px;
}

.center-content{
    justify-content: center;
}

.messages{
    height: 450px;
    overflow: scroll;
    margin-right: 32px;
    margin-left: 32px;
    margin-top: 32px;
}

.goBack{
    margin-right: 4%;
    margin-top: 4%;
}

.goBackIcon{
    font-size: 24px;
    text-decoration: none;
    color:black;
    font-weight: bold;
}

.chat-list{
    text-align: center;
    margin-top: 18%;
    font-size: 32px;
}

@media (max-width: 600px){
    .chat-side-menu{
        height: 100vh;
        background-color:white;
    }
    .chat{
        height: 90vh;
        background-color:#F5F5F7;
        min-height: fill-available;
        min-height: -webkit-fill-available;
        margin-bottom: 0%;
    }
    .chats-title{
        font-size: 24px;
        font-weight: bold;
        margin-top:4%;
    }
    .messages{
        height: 68vh;
    }
    html{
        height: -webkit-fill-available;
        height: -webkit-fill-available;
    }
}

@media (max-width: 375px){
    .chat{
        height: 88vh;
        background-color:#F5F5F7;
        min-height: fill-available;
        min-height: -webkit-fill-available;
    }
    .messages{
        height: 60vh;
    }
}




