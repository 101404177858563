.dashboardTitle{
    color:black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 28px;
    width: 90%;
}

.dashboardSelect{
    width: 56%;
    margin-left:14px;
}

.btn-add-product-mobile{
    color: white;
    border: 1px solid #E75B10;
    background-color: #E75B10;
    border-radius: 32px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    margin-left: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
    width: 156px;
    height: 56px;
   
}

.btn-compartir2:hover{
    color: white;
}

@media (min-width: 350px){
    .dashboardTitle{
        color:black;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 22px;
    }
}

@media(min-width: 380px){
    .dashboardTitle{
        color:black;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 28px;
    }
}

@media(max-width: 600px){
    .bottom-mobile-dashboard-negocio{
        margin-bottom:32%;
    }
}