.center{
    text-align:center;
}

.left{
    text-align:left;
    margin-left: 24px;
}

.bold{
    font-weight: bold;
}

.title{
    font-size: 42px;
}

.title-section{
    margin-top: 8%;
}

.title-helper{
    margin-top: 2%;
    font-size: 22px;
    color: grey;
}

.btn-title-section{
    background-color: #E75B10;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    padding:16px;
}

.btn-title-section:hover{
    color: white;
}

.pricing-plan{
    background-color:white;
    padding-top:36px;
    padding-bottom:36px;
    border-radius: 16px;
    margin-top: 28%;
    width: 424px;
    height: 500px;
}

.pricing-plan-title{
    font-size: 24px;
}

.pricing-plan-price{
    font-size: 32px;
    font-weight: bold;
    color: #42577A;
}

.pricing-plan-icon{
    color: #E75B10;
}

@media (max-width:1100px){
    .pricing-plan{
        margin-top: 32%;
    }
}

@media (max-width:900px){
    .pricing-plan{
        margin-top: 42%;
    }
}

@media (max-width:600px){
    .title{
        font-size: 24px;
    }

    .title-helper{
        margin-top: 8%;
        font-size: 16px;
        color: grey;
    }

    .pricing-plan{
        margin-top: 74%;
        width: 364px;
        height: 500px;
    }

    .pricing-plan-price{
        font-size: 24px;
        font-weight: bold;
        color: #42577A;
    }
}

@media (max-width:375px){
    .title{
        font-size: 24px;
    }

    .title-helper{
        margin-top: 8%;
        font-size: 16px;
        color: grey;
    }

    .pricing-plan{
        margin-top: 80%;
        width: 324px;
        height: 500px;
    }

}