.btn-inventory-util{
    color: #E75B10;
    border: 1px solid #E75B10;
    border-radius: 25px;
    padding-right: 24px;
    padding-left: 24px;
    height: 42px;
    font-size: 16px;
}

.btn-inventory-util:hover{
    color: white;
    background-color: #E75B10;
}

.noDisplay{
    display: none;
}

.error{
    color:red;
}
