.home-mobile{
    padding-bottom:24%;
}

.section{
    margin-top:32px;
}

.section-title{
    color:black;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    font-size: 22px;
}

.img-scroll-section{
    height: 122px;
    width: 266px;
    margin-right:16px;
}

.scroll-images{
    overflow: auto;
    white-space: nowrap;
}

.scrollmenu img {
    display: inline-block;
    color: black;
}

.scrollmenu::-webkit-scrollbar {
    -webkit-appearance: none;
}

.scrollmenu::-webkit-scrollbar:horizontal {
    width: 4px;
    height: 4px;
    
}

.scrollmenu::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #9B9B9B;
}

.exporar-mas{
    margin-top: 24px;
}

.explorar-ocultar{
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700px;
    color:#4A90E2;
}

.banner-home-mobile{
    background-color: #EE801E;
    border-radius: 8px;
    color: white;
    padding-top: 16px;
    padding-bottom: 8px;
    padding-left: 16px;
    height: 92px;
    text-decoration: none;
}

.banner-home-mobile:active {
    color: white;
}

.banner-home-mobile-text{
    font-size: 18px;
}

.banner-home-mobile-icon{
    font-size: 32px;
    margin-top: 4px;
    margin-left: 24px;
}

.producto-estrella{
   margin-top: -24px;
}

.producto-estrella-section{
    margin-bottom: 24px;
    margin-top: 48px;
    background-color: white;
    padding-top: 32px;
    padding-bottom: 36px;
    padding-left: 16px;
    border-radius: 12px;
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.1);
 }

 .section-products{
    margin-top:32px;
    margin-bottom: 32px;
    background-color: white;
    padding-top: 32px;
    padding-bottom: 24px;
    padding-left: 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.1);
 }

 .mejores-productos{
    height: 146px;
    width: 100%;
    margin-right:16px;
 }