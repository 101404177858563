.chat-send-form-container{
    position: absolute;
    height: 64px;
    bottom: 0;
    border-top: 1px solid  #E3E0DD;;
    width: 100%;
}

.input-send-message{
    height: 42px;
    width: 80%;
    margin-top: 12px;
    border-radius: 24px;
    border: none;
    background-color: #F5F5F7;
    margin-left: 16px;
}

@media (max-width: 600px){
    .input-send-message{
        height: 42px;
        width: 70%;
        margin-top: 12px;
        border-radius: 24px;
        border: none;
        background-color: white;
        margin-left: 16px;
    }
}


@media (max-width: 360px){
    .input-send-message{
        height: 42px;
        width: 67%;
        margin-top: 12px;
        border-radius: 24px;
        border: none;
        margin-left: 16px;
    }
}