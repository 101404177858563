.landingPage-content{
    background-color: white;
    padding-bottom:8%;
    width:100%;
}

.landingPage-section-1{
    width:100%;
    position:relative;
    background-color: white;
}

.landing-section-1-center-mobile{
    display:none;
}


.landing-section-1-right{
    text-align: right;
    width: 100%;
}

.landing-section-1-left{
    margin-left: 8%;
    margin-top:8%;
    position: absolute;;
}

.landing-section-1-left-text{
    font-size: 56px;
    font-family: 'Open Sans', sans-serif;
}


.landing-section-1-right-img{
   height: 82%;
}

.landing-section-1-left-text-pg-proveedores{
    margin-top: 32px;
    font-size: 20px;
    width: 85%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
}

.btn-landing-section-1{
    background-color: #E75B10;
    color: white;
    margin-top:32px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    padding:16px;
}

.landing-section-1-center{
    text-align: center;
    width: 100%;
}

.landing-section-1-center-text{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 42px;
    margin-top:-6%;
}

.landing-section-1-center-text-pg{
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 20px;
    margin-top:24px;
    color: #808083;
}

.landingPage-section-2{
    width:100%;
    position:relative;
    background-color: white;
}

.landing-section-2-center-image{
    display: none;
}

.landing-section-2-left{
    margin-left: 8%;
    margin-top:8%;
    position: absolute;
}

.landing-section-2-left-text{
    font-size: 42px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.landing-section-bulletpoints{
    margin-top:32px;
}

.bi-check-circle-fill{
    font-size: 18px;
}

.landing-section-bulletpoints-text{
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
}

.landing-section-2-right{
    text-align: right;
    width: 100%;
}

.landing-section-2-right-img-proveedores{
    margin-top:4%;
    height: 70%;
    margin-right: 8%;
}

.landingPage-section-3{
    width:100%;
    position:relative;
    background-color: white;
}

.landing-section-3-left-img{
    height: 82%;
    margin-left: 12%; 
}

.landing-section-3-right{
    margin-left:-2%;
}

.landing-section-3-right-text{
    margin-top:8%;
    font-size: 42px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.landing-section-number{
    font-size: 14px;
    font-weight: bold;
    background-color: black;
    color: white;
    border-radius: 16px;
    height: 24px;
    padding-top:1px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 8px;
}

.landingPage-section-4{
    width:100%;
    position:relative;
    background-color: white;
}

.landing-section-4-center-image{
    display: none;
}


.landing-section-4-right{
    text-align: right;
    width: 100%;
}

.landing-section-4-right-img{
    margin-top:6%;
    height: 78%;
    margin-right: 8%;
}

.landing-section-4-left{
    margin-left: 8%;
    margin-top:8%;
    position: absolute;
}

.landing-section-4-left-text-helper{
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    margin-top:24px;
}

.landingPage-section-5{
    width:100%;
    position:relative;
    background-color: white;
}

.landing-section-5-center{
    text-align: center;
    width: 100%;
}

.landing-section-5-accordion{
    width: 86%;
    margin-left:6%;
}


.landing-section-5-center-text{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 42px;
    margin-top:8%;
}

.landing-section-5-left{
    margin-top: 4%;
}

.landing-section-5-left-video{
    height: 400px;
    width: 600px;
    margin-left: 12%; 
}

.landing-section-5-right{
    text-align: right;
}

.landing-section-5-right-text{
    margin-top:20%;
    margin-right: 24%;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-style: italic;
}

.landing-section-5-right-text-name{
    margin-right: 24%;
    font-size: 24px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.right-btn{
    margin-right: 24%;
}

.landingPage-section-6{
    width:100%;
    position:relative;
    background-color: white;
}

.landing-section-6-center{
    text-align: center;
    width: 100%;
}

.landing-section-6-center-text{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 48px;
    margin-top:12%;
}

.btn-landing-section-6{
    background-color: #E75B10;
    color: white;
    margin-top:42px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    padding:16px;
    font-size: 24px;
}

.btn-landing-section-6:hover{
    color: white;
}

.btn-landing-section-1:hover{
    color: white;
}

@media (max-width:1320px){
    .landing-section-1-left-text{
        font-size: 52px;
    }
    
    .landing-section-1-left-text-pg-proveedores{
        font-size: 20px;
    }
}

@media (max-width:1250px){

    /* Section 1 */
    .landing-section-1-left-text{
        font-size: 48px;
    }
    
    .landing-section-1-left-text-pg-proveedores{
        font-size: 18px;
    }

    .landing-section-1-right-img{
        height: 72%;
    }

     .landing-section-1-center-text{
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 36px;
        margin-top:-9%;
    }

    /* Section 2 */
    .landing-section-2-right-img-proveedores{
        margin-top:4%;
        height: 64%;
        margin-right: 8%;
    }

    /* Section 3 */
    .landing-section-3-left-img{
        height: 72%;
        margin-left: 12%; 
    }

    /* Section 4 */
    .landing-section-4-right-img{
        margin-top:6%;
        height: 64%;
        margin-right: 8%;
    }

    /* Testimonio */
    .landing-section-5-center-text{
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 36px;
        margin-top:6%;
    }

    .landing-section-5-left-video{
        height: 400px;
        width: 600px;
        margin-left: 12%; 
    }
    
    .landing-section-5-right-text{
        margin-right: 20%;
    }

    .landing-section-5-right-text-name{
        margin-right: 20%;
    }
    
    .right-btn{
        margin-right: 20%;
    }
    
}

@media (max-width:1150px){
    /* Section 1 */
    .landing-section-1-left-text{
        font-size: 44px;
    }
    
    .landing-section-1-left-text-pg-proveedores{
        font-size: 18px;
    }

    .landing-section-1-right-img{
        height: 64%;
    }

     .landing-section-1-center-text{
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 36px;
        margin-top:-14%;
    }

    /* Section 2 */
    .landing-section-2-right-img-proveedores{
        margin-top:4%;
        height: 56%;
        margin-right: 8%;
    }

    .landing-section-2-left-text{
        font-size: 36px;
    }

    /* Section 3 */
    .landing-section-3-left-img{
        height: 64%;
        margin-left: 12%; 
    }

    .landing-section-3-right-text{
        font-size: 36px;
    }

    /* Section 4 */
    .landing-section-4-right-img{
        margin-top:6%;
        height: 64%;
        margin-right: 8%;
    }

    /* Testimonio */
    .landing-section-5-left-video{
        height: 300px;
        width: 500px;
        margin-left: 12%; 
    }
    
    .landing-section-5-right-text{
        margin-right: 20%;
    }

    .landing-section-5-right-text-name{
        margin-right: 20%;
    }
    
    .right-btn{
        margin-right: 20%;
    }

    /* Section 6 */
    .landing-section-6-center-text{
        font-size: 42px;
        margin-top:12%;
    }

    .btn-landing-section-6{
        background-color: #E75B10;
        color: white;
        margin-top:42px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        padding:16px;
        font-size: 20px;
    }
    
}

@media (max-width:1050px){
    /* Section 1 */
    .landing-section-1-left-text{
        font-size: 38px;
    }

    .landing-section-1-left-text-pg-proveedores{
        font-size: 16px;
    }

    .landing-section-1-right-img{
        height: 64%;
    }

    .landing-section-1-center-text{
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 32px;
        margin-top:-18%;
    }

    /* Section 2 */
    .landing-section-2-right-img-proveedores{
        margin-top:4%;
        height: 52%;
        margin-right: 8%;
    }

    .landing-section-2-left-text{
        font-size: 32px;
    }

    /* Section 3 */
    .landingPage-section-3{
        margin-top:-8%;
    }

    .landing-section-3-left-img{
        height: 58%;
        margin-left: 12%;
    }

    .landing-section-3-right-text{
        font-size: 32px;
    }

    /* Section 4 */
    .landingPage-section-4{
        margin-top:-8%;
    }

    .landing-section-4-right-img{
        margin-top:6%;
        height: 56%;
        margin-right: 8%;
    }

    /* Testimonio */
    .landingPage-section-5{
        margin-top:-4%;
    }

    .landing-section-5-left-video{
        height: 256px;
        width: 464px;
        margin-left: 12%; 
    }

    .landing-section-5-right-text{
        margin-top:16%;
        margin-right: 20%;
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 500;
        font-style: italic;
    }
    
    .landing-section-5-right-text-name{
        margin-right: 20%;
        font-size: 20px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
    }

    .right-btn{
        margin-right: 20%;
    }

    /* Section 6 */
    .landing-section-6-center-text{
        font-size: 42px;
        margin-top:12%;
    }

    .btn-landing-section-6{
        background-color: #E75B10;
        color: white;
        margin-top:42px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        padding:16px;
        font-size: 20px;
    } 
}

@media (max-width:950px){
    /* Section 1 */
    .landing-section-1-left-text{
        font-size: 36px;
    }

    .landing-section-1-left-text-pg-proveedores{
        font-size: 14px;
    }

    .landing-section-1-right-img{
        height: 56%;
    }

    .landing-section-1-center-text{
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 32px;
        margin-top:-26%;
    }

    /* Section 2 */
    .landing-section-2-right-img-proveedores{
        margin-top:4%;
        height: 50%;
        margin-right: 8%;
    }

    .landing-section-2-left-text{
        font-size: 32px;
    }

    /* Section 3 */
    .landingPage-section-3{
        margin-top:-12%;
    }

    .landing-section-3-left-img{
        height: 54%;
        margin-left: 12%;
    }

    .landing-section-3-right-text{
        font-size: 32px;
    }

    /* Section 4 */
    .landingPage-section-4{
        margin-top:-8%;
    }

    .landing-section-4-right-img{
        margin-top:6%;
        height: 50%;
        margin-right: 8%;
    }

    /* Testimonio */
    .landingPage-section-5{
        margin-top:-4%;
    }

    .landing-section-5-left-video{
        height: 224px;
        width: 432px;
        margin-left: 12%; 
    }

    .landing-section-5-right-text{
        margin-top:16%;
        margin-right: 20%;
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 500;
        font-style: italic;
    }
    
    .landing-section-5-right-text-name{
        margin-right: 20%;
        font-size: 18px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
    }

    .right-btn{
        margin-right: 20%;
    }

    /* Section 6 */
    .landing-section-6-center-text{
        font-size: 42px;
        margin-top:12%;
    }

    .btn-landing-section-6{
        background-color: #E75B10;
        color: white;
        margin-top:42px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        padding:16px;
        font-size: 20px;
    } 
}

@media (max-width:850px){
    /* Section 1 */
    .landing-section-1-left-text{
        font-size: 30px;
    }

    .landing-section-1-left-text-pg-proveedores{
        font-size: 12px;
    }

    .landing-section-1-right-img{
        height: 56%;
    }

    .landing-section-1-center-text{
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 28px;
        margin-top:-26%;
    }

    /* Section 2 */
    .landing-section-2-right-img-proveedores{
        margin-top:4%;
        height: 48%;
        margin-right: 8%;
    }

    .landing-section-2-left-text{
        font-size: 28px;
    }

    /* Section 3 */
    .landingPage-section-3{
        margin-top:-8%;
    }

    .landing-section-3-left-img{
        height: 54%;
        margin-left: 12%;
    }

    .landing-section-3-right-text{
        font-size: 28px;
    }

    /* Section 4 */
    .landingPage-section-4{
        margin-top:-8%;
    }

    .landing-section-4-right-img{
        margin-top:6%;
        height: 46%;
        margin-right: 8%;
    }

    /* Testimonio */
    .landingPage-section-5{
        margin-top:-4%;
    }

    .landing-section-5-left-video{
        height: 204px;
        width: 364px;
        margin-left: 12%; 
    }

    .landing-section-5-right-text{
        margin-top:16%;
        margin-right: 20%;
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 500;
        font-style: italic;
    }
    
    .landing-section-5-right-text-name{
        margin-right: 20%;
        font-size: 18px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
    }

    .right-btn{
        margin-right: 20%;
    }

    /* Section 6 */
    .landing-section-6-center-text{
        font-size: 42px;
        margin-top:12%;
    }

    .btn-landing-section-6{
        background-color: #E75B10;
        color: white;
        margin-top:42px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        padding:16px;
        font-size: 20px;
    } 
}

@media (max-width:600px){

    .landingPage-content{
        padding-bottom:24%;
    }

    /* Section 1 */

    .landingPage-section-1{
       width: 100%;
    }

    .landing-section-1-right{
        display:none;
    }

    .landing-section-1-center-mobile{
        display: inline-block;
        margin-top:-32%;
        overflow:hidden;
    }

    .landing-section-1-center-img{
        height: 58%;
    }

    .landing-section-1-left{
        margin-left: 8%;
        margin-top:8%;
        position: relative;
        text-align: left;
        padding-bottom:48%;
    }

    .landing-section-1-left-text{
        font-size: 36px;
        width: 90%;
    }

    .landing-section-1-center{
        width:90%;
        margin-left:6%;
    }

    .landing-section-1-left-text-pg-proveedores{
        font-size: 16px;
    }

    .landing-section-1-center-text{
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 30px;
        margin-top:-78%;
    }


    /* Section 2 */
    .landingPage-section-2{
        width: 90%;
        margin-top:8%;
    }

    .landing-section-2-left{
        margin-left: 8%;
        margin-top:8%;
        position: relative;
        text-align: left;
        padding-bottom:48%;
    }

    .landing-section-2-center-image{
        display: inline-block;
        width: 100%;
        text-align: center;
    }

    .landing-section-2-right{
        display:none;
    }

    .landing-section-2-center-img{
        margin-top:16%;
        height: 52%;
        margin-left: 8%;
    }

    .landing-section-2-left{
        margin-top:-48%;
    }

    .landing-section-2-left-text{
        font-size: 30px;
    }

    /* Section 3 */
    .no-display-mobile{
        display: none;
    }
    
    .landingPage-section-3{
        margin-top:-24%;
    }

    .landing-section-3-left-img{
        height: 54%;
        margin-left: 8%;
    }

    .landing-section-3-right{
        margin-left:8%;
        margin-right:0%;
        width: 90%;
        margin-top:-46%;
    }
    
    .landing-section-3-right-text{
        margin-top:8%;
        font-size: 30px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
    }

     /* Section 4 */
     .landingPage-section-4{
        margin-top:0%;
    }

    .landing-section-4-right{
        display:none;
    }

    .landing-section-4-center-image{
        display: inline-block;
        width: 100%;
        text-align: center;
    }
   
    .landing-section-4-center-img{
        margin-top:24%;
        height: 46%;
        margin-left: 2%;
    }

    .landing-section-4-left{
        margin-left: 8%;
        margin-top:8%;
        position: relative;
        text-align: left;
        margin-top:-32%;
    }

    .landing-section-4-left-text-helper{
        width: 90%;
    }

     /* Testimonio */
    .landingPage-section-5{
        margin-top:24%;
    }

    .landing-section-5-left{
        text-align: center;
    }

    .landing-section-5-left-video{
        height: 204px;
        width: 364px;
        margin-left: 0%;
    }

    .landing-section-5-right{
        text-align: center;
    }

    .landing-section-5-right-text{
        margin-top:8%;
        margin-right: 0%;
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 500;
        font-style: italic;
    }
    
    .landing-section-5-right-text-name{
        margin-right: 0%;
        font-size: 18px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
    }

    .right-btn{
        display:none;
    }

    /* Section 6 */
    .landing-section-6-center-text{
        font-size: 42px;
        margin-top:12%;
    }

    .btn-landing-section-6{
        background-color: #E75B10;
        color: white;
        margin-top:42px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        padding:16px;
        font-size: 20px;
    } 

}