.template1{
    background-color:white;
    font-family: 'Modak', cursive;
    font-weight: 400;
}

.template1-en-catalogo{
    background-color: none;
}

.navbar-template{
    width: 100%;
    text-align: center;
}

.nombreCatalogo{
    font-size: 32px;
    color: black;
    background-color: transparent;
    padding-top: 16px;
    cursor: pointer;
}

.categories{
    font-size: 16px;
    color: black;
    font-family: 'Archivo', sans-serif;
    align-items: center;
    justify-content: center;
}

.category{
    cursor: pointer;
}

.ethos-template1{
    font-family: 'Archivo', sans-serif;
    justify-content: center;
    height: 200px;
    background-color:#D8E9A0;
    text-align: center;
}

.divider-template1{
    border: 1px solid black;
    width: 100%;
}

.first-content-template1{
    background-color: #C4BFE4;
    padding-bottom: 64px;
}

.banner-first-template1{
    width: 296px;
    height: 492px;
    margin-top:24px;
    border-radius: 8px;
}

.products{
    font-family: 'Archivo', sans-serif;
    font-size: 14px;
}

.invite{
    padding-top: 24px;
    color: white;
    font-family: 'Archivo', sans-serif;
    align-items: left;
    justify-content: left;
}

.inviting-text-big{
    font-size: 56px;
    font-weight: bold;
}

.inviting-text-ethos{
    font-size: 24px;
}

.ethos-text-template1{
    background-color:#D8E9A0;
    text-align: center;
    color: black;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 36px;
    padding-right: 36px;
    border-radius: 36px;
}

.btn-black{
    background-color:black;
    color: white;
    text-align: center;
}

.second-content-template1{
    background-color: #ECF1F4;
    padding-top: 64px;
    padding-bottom: 64px;
    font-family: 'Archivo', sans-serif;
}

.banner-second-template1{
    margin-left: 25%;
    width: 292px;
    height: 364px;
    border-radius: 8px;
}


.nosotros-title-template1{
    font-size: 48px;
    font-weight: bold;
}

.nosotros-text-template1{
    font-size: 18px;
}

.third-content-template1{
    background-color:white;
    padding-top: 24px;
    padding-bottom: 64px;
}

.products-title-template1{
    font-family: 'Archivo', sans-serif;
    font-weight: bold;
    font-size: 48px;
    text-align: left;
}

.fourth-content-template1{
    padding-top: 48px;
    padding-bottom: 48px;
    background-color:#D8E9A0;
}

.reviews-title-template1{
    font-family: 'Archivo', sans-serif;
    font-weight: bold;
    font-size: 48px;
    text-align: center;
}

.testimonial-template1{
    font-family: 'Archivo', sans-serif;
    text-align: center;
}

.testimonial-text-template1{
    font-size: 24px;
}

.testimonial-name-template1{
    font-size: 24px;
    font-weight: bold;
}

.testimonial-img-template1{
    width: 164px;
    height: 204px;
}

.fifth-content-template1{
    background-color: black;
    color: white;
    padding-top: 48px;
    padding-bottom: 48px;
    width: 100%;
}

.footer-name-template1{
    font-size: 32px;
    text-align: left;
    align-items: left;
    justify-content: left;
}


.footer-social-template1{
    font-size: 24px;
}

.footer-derechos-template1{
    font-size: 18px;
    font-family: 'Archivo', sans-serif;
}

.no-display-md{
    display: none;
}

.scrollmenu-template1{
    overflow: auto;
    white-space: nowrap;
}

.scrollmenu-template1 p {
    display: inline-block;
    color: black;
    text-decoration: none;
}
  
.scrollmenu-template1 p:hover {
    color: black;
}

.scrollmenu-template1::-webkit-scrollbar {
    -webkit-appearance: none;
}

.scrollmenu-template1::-webkit-scrollbar:horizontal {
    width: 4px;
    height: 4px;
    
}

.scrollmenu-template1::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #9B9B9B;
}

.floating{
    position:fixed;
    width:60px;
    height:60px;
    bottom:40px;
    right:40px;
    background-color:#25d366;
    color:white;
    border-radius:50px;
    text-align:center;
    font-size:38px;
    box-shadow: 2px 2px 3px #999;
    z-index:100;
}

.floating:hover{
    color: white;
}

.float-button{
    margin-top:16px;
    margin-left: 4px;
}

.float-button:hover{
    color:white;
}

/* TABLET RESPONSIVENESS */
@media (max-width: 900px){
    .banner-second-template1{
        margin-left: 0%;
        width: 292px;
        height: 364px;
        border-radius: 8px;
    } 


    .no-display-tablet{
        display: none;
    }

    .inviting-text-big{
        font-size: 56px;
        text-align: left;
    }

    .inviting-text-ethos{
        font-size: 22px;
    }

    .ethos-text-template1{
        justify-content: center;
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 26px;
        padding-right: 26px;
        border-radius: 32px;
    }

}

/* MOBILE RESPONSIVENESS */
@media (max-width: 600px){
    .banner-second-template1{
        margin-left: 0%;
        width: 292px;
        height: 364px;
        border-radius: 8px;
    } 

    .nosotros-title-template1{
        text-align: center;
    }

    .nosotros-text-template1{
        text-align: center;
    }

    .products-title-template1{
        text-align: center;
    }

    .no-display-sm{
        display: none;
    }

    .no-display-md{
        display: inline;
    }

    .inviting-text-big{
        font-size: 38px;
        margin-left: 8px;
        text-align: left;
    }

    .parent-center{
        display: flex;
        justify-content: center;
    }

    .inviting-text-ethos{
        font-size: 22px;
    }

    .ethos-text-template1{
        margin-left: 8px;
        margin-right: 8px;
        justify-content: center;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 24px;
        padding-right: 24px;
        border-radius: 36px;
    }

    .scrollmenu-template1 p {
        display: inline-block;
        color: black;
        text-align: center;
        padding-left: 4px;
        padding-right: 4px;
        padding-top:4px;
        text-decoration: none;
    }

    .template1-en-catalogo{
        background-color: white;
    }

    .categories{
        margin-left: -16px;
        align-items: left;
        justify-content: left;
    }

    .floating{
        position:fixed;
        width:60px;
        height:60px;
        bottom:100px;
        right:18px;
        background-color:#25d366;
        color:white;
        border-radius:50px;
        text-align:center;
        font-size:36px;
        box-shadow: 2px 2px 3px #999;
        z-index:100;
    }
    
    .floating:hover{
        color: white;
    }
    
    .float-button{
        margin-top:16px;
        margin-left: 4px;
    }
    
    .float-button:hover{
        color:white;
    }
}