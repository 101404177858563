/* COLORES */
body{
    /* background-color:#F2F3F7; */
    background-color:#F5F5F7;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    overflow-x: hidden;
    overflow-y:visible;
}

ul{
    list-style: none;
}

.custom-container{
    margin-left:4%;
    margin-right: 4%;
}

.checkoutBackground{
    background-color:white;
    width: 94%;
    border-radius:8px;
    padding-top: 22px; 
    padding-bottom: 48px;
}

.ctaVendedorBackground{
    background-color:white;
    width: 92%;
    border-radius:8px;
    padding-top: 22px; 
    padding-bottom: 48px;
}

.justify-text{
    text-align: justify;
    text-justify: inter-word;
}

.banner{
    width: 100%;
    height: 32px;
    color: white;
    background-color: #3B5B84;
}

.banner-text{
    padding-top:4px;
    padding-left:4px;
    padding-right: 4px;
    text-align: center;
    font-size: 14px;
}

.banner-icon{
    cursor: pointer;
}



.kilig-black-text{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color:#E75B10;
    font-size:24px;
}

.nav-logo-kanguru{
    width: 20%;
    height: 20%;
}


.nav-black-text{
    color: black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size:16px;
}

.nav-black-text:hover{
    color: black;
}

.btn-primary{
    background-color: #E75B10;
    border-style: none;
    font-weight: bold;
}

.uploadImageBtn{
    color: #E75B10;
    border: 1px solid #E75B10;
    border-radius: 4px;
    padding:5px;
    font-size: 14px;
    cursor: pointer;
}

.uploadImageBtn:hover{
    color: white;
    background-color: #E75B10;
}

.btn-import{
    color: #E75B10;
    border: 1px solid #E75B10;
    border-radius: 25px;
    padding-right: 24px;
    padding-left: 24px;
    height: 42px;
}

.btn-no-import{
    pointer-events: none;
}

.contentAddProduct{
    background-color: white;
    border-radius: 8px;
    padding: 24px;
}

.btn-import:hover{
    color: white;
    background-color: #E75B10;
}

.btn-primary-cliente{
    background-color: #00A3D5;
    color: white;
    border-style: none;
    font-weight: bold;
}

.btn-primary-cliente:hover{
    color: white;
}

.btn-fotos-PD{
    background-color: #F6BD48;
    border-style: none;
    font-weight: bold;
    color:white;
    font-size:18px;
}

.copyIcon{
    background-color: #fb6f92;
    border-style: none;
    font-weight: bold;
    color:white;
    font-size:16px;
    margin-bottom: -0px;
    margin-top: -8px;
}

.btn-info-PD{
    background-color: #fb6f92;
    border-style: none;
    font-weight: bold;
    color:white;
    font-size:18px;
}

.btn-info-PD2{
    background-color: #fb6f92;
    border-style: none;
    font-weight: bold;
    color:white;
    font-size:22px;
}

.btn-info-PD:hover{
    color:white;
}

.btn-info-PD2:hover{
    color:white;
}

.btn-fotos-PD:hover{
    color:white;
}

.info{
    font-size: 12px;
    margin-top:-10px;
    width: 70%;
    text-align: center;
}

.btn-pago-PD{
    background-color: #00A650;
    border-style: none;
    font-weight: bold;
    color:white;
    font-size:18px;
}

.btn-primaryMobile{
    background-color: #E75B10;
    border-style: none;
    font-size: 12px;
    color:white;
}

.btn-primary-cliente-mobile{
    background-color: #00A3D5;
    border-style: none;
    font-size: 12px;
    color:white;
}

.btn-primary:hover{
    background-color: #E75B10;
}

.btn-secondary{
    background-color: black;
    border-style: none;
    font-size: 14px;
}

.btn-retirar{
    background-color: #E75B10;
    border-style: none;
    font-size: 14px;
}

.btn-grey-landing{
    background-color: #9B9B9B;
    border-radius: 12px;
    margin-top: 32px;
    border-style: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 28px;
    color: white;
    text-align: left;
    line-height: 1.4;
    pointer-events: none;

}

.btn-primaryLanding{
    background-color: #E75B10;
    border-style: none;
    border-radius: 8px;
    font-size: 18px;
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top:32px;
    color:white;
    font-weight: bold;
}

.btn-primaryLanding:hover{
    color: white;
}

.btn-secondary:hover{
    background-color: black;
}

.btn-product{
    background-color:#F5F5F7;
    color: #E75B10;
    font-size: 12px;
    font-weight: bold;
    border-style: none;
    border-radius: 25px;
    height: 45px;
    width: 120px;
}

.btn-categoria{
    background-color:#b09fcb;
    color: white;
    font-size: 12px;
    border-style: none;
    border-radius: 25px;
    height: 30px;
    width: 80px;
}

.btn-enviado{
    background-color:#00D650;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    pointer-events: none;
}

.btn-confirmar{
    background-color:#F6BD48;
    /* background-color: #EED202; */
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

.btn-revisar{
    background-color:#3B5B84;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    pointer-events: none;
}

.btn-revisar:hover{
    color: white;
}

.btn-confirmar:hover{
    color: white;
}

.btn-shippingLabelPending{
    background-color: #EED202;
    color: white;
    border-radius: 24px;
    height: 46px;
    font-size: 20px;
    margin-top: 16px;
    margin-left: 16px;
}

.btn-shippingLabelPending:hover{
    color: white;
}


.reto-falta{
    color:#E75B10;
}

.progress-bar-reto{
    background-color:#E75B10;
}

.reto-actual{
    border: none;
}

.retos-completados{
    width: 100%;
    margin-top:16px;
    height: 100%;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top:24px;
    padding-bottom:24px;
    background-color:white;
}


.enCaminoEstatus{
    color:#2183E6;
    font-size: 16px;
    margin-top: 2%;
    margin-bottom:1%;
    font-weight: bold;
}

.entregadaEstatus{
    color: #00D650;
    font-size: 16px;
    margin-top: 2%;
    margin-bottom:1%;
    font-weight: bold;
}

.draftEstatus{
    color: #F6BD48;
    font-size: 16px;
    margin-top: 2%;
    margin-bottom:1%;
    font-weight: bold;
}

.enCaminoRecolectarEstatus{
    color: #9EE9F0;
    font-size: 16px;
    margin-top: 2%;
    margin-bottom:1%;
    font-weight: bold;
}

.collectedEstatus{
    color: #A488FB;
    font-size: 16px;
    margin-top: 2%;
    margin-bottom:1%;
    font-weight: bold;
}

.canceladaEstatus{
    color: #e40b00;
    font-size: 16px;
    margin-top: 2%;
    margin-bottom:1%;
    font-weight: bold;
}


.btn-enviado:hover{
    color: white;
}

.btn-grey{
    background-color:#9B9B9B;
    font-weight: bold;
    color:white;
    border-style: none;
}
.btn-grey:hover{
    color: white;
}

.btn-green{
    background-color:#00D650;;
    font-weight: bold;
    color:white;
    border-style: none;
}
.btn-green:hover{
    color: white;
}

.redText{
    color:#e40b00;
}

/* Divider */
.divider{
    height: 1px;
    width: 100%;
    background-color: #E3E0DD;
}

/* NAVBAR */
.logo{
    width: 100px;
    height: 40px;
    margin-right: 10%;
}

.landing-logo{
    margin-top:12%;
}

.logo_catalogo{
    width: 200px;
    height: 48px;
    margin-right: 10%;
}

.logo_kangupago{
    width: 178px;
    height: 46px;
    margin-right: 10%;
}


.logo_catalogo_footer{
    width: 200px;
    height: 54px;
    margin-left: 40%;
    background-color: white;
    border-radius: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
}

.logo_kangupago_footer{
    width: 174px;
    height: 54px;
    margin-left: 44%;
    background-color: white;
    border-radius: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
}

.logoCheckout{
    width: 100%;
    text-align: center;
    margin-left:10%;
}

.navbar{
    background-color:white;
    height: 64px;
    overflow: hidden;
}


.landing-section-navbar-decor{
    width: 100%;
    text-align: left;
}

.landing-section-navbar-decor-img{
    height: 108px;
}

.search-bar{
    width: 48%;
    margin-top:4%;
    margin-bottom: 4%;
}

.input-search-bar{
    border-radius: 24px;
}

.nav-link{
    font-size: 14px;
}

.navbar.bottom-navbar{
    background-color: white;
    color:black;
    position: fixed;
    height: 72px;
    width: 100%;
    overflow-x: auto;
    border-top: 1px solid #F5F5F7;
    font-size: 10px;
}

.icon-bottom-navbar{
    font-size: 24px;
}

.btn-bottom-navbar{
    background-color: transparent;
    color: black;
    font-size:10px;
}

@media(max-width:330px){
    .btn-bottom-navbar{
        font-size:8px;
    }
    
}

.nav-links-top{
    width:100%;
    text-align:right;
}

.navbar-icons{
    margin-top:-2%;
}

.sign-in-up-nav{
    color:black;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.sign-in-up-nav:hover{
    color:#E75B10;
}

.navbar-top{
    padding-top:1px;
    padding-bottom: 1px;
    background-color:#F5F5F7;
    overflow: auto;
}

.navbar-bottom{
    padding-top:80px;
    padding-bottom: 80px;
    background-color:#E75B10;
}

.nav-title{
    color:white;
    width:100%;
    margin-top:-40px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.nav-buttons{
    position: absolute;
    margin-top: 1.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.btn-nav{
    height: 100%;
    background-color:white;
    color:black;
    border-style: none;
    font-size: 14px;
    border-radius: 8px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    margin-right:16px;

}

.country-operation{
    height: 42px;
    width: 42px;
    position: absolute;
    top: 18%;
    left: 90%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.scrollmenu {
    background-color: white;
    overflow: auto;
    white-space: nowrap;
    margin-left:-16px;
}

.scrollmenu p {
    display: inline-block;
    color: black;
    text-align: center;
    padding-left: 14px;
    padding-right: 14px;
    padding-top:4px;
    text-decoration: none;
}

input[type=radio]{
    transform:scale(1.3);
    cursor: pointer;
}
  
.scrollmenu p:hover {
    color: #E75B10;
}

.scrollmenu::-webkit-scrollbar {
    -webkit-appearance: none;
}

.scrollmenu::-webkit-scrollbar:horizontal {
    width: 4px;
    height: 4px;
    
}

.scrollmenu::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #9B9B9B;
}

.imgThumbnailList{
    height: 64px;
    width: 64px;
    margin-right:16px;
}

.floating{
    position:fixed;
    width:60px;
    height:60px;
    bottom:40px;
    right:40px;
    background-color:#25d366;
    color:white;
    border-radius:50px;
    text-align:center;
    font-size:36px;
    box-shadow: 2px 2px 3px #999;
    z-index:100;
}

.floating:hover{
    color: white;
}

.float-button{
    margin-top:16px;
    margin-left: 4px;
}

.float-button:hover{
    color:white;
}
  


@media(min-width:600px){
    .scrollmenu{
        display: none;
    }
    .ctaVendedorBackground{
        background-color:#F5F5F7;
    }
}

@media (max-width: 600px) { 
    /* .navbar-toggler{
        display: none;
    }
    /* .searchMobile{
        display:none;
    } */
    input[type=radio]{
        transform:scale(1.6);
        cursor: pointer;
    }
    
    .custom-container{
        margin-left:0%;
        margin-right: 0%;
    }
    .custom-container.ctaVendedorBackground{
        margin-left:4%;
        margin-right: 4%;
        padding-bottom: 16%;
    }
    .search-bar{
        width: 100%;
        margin-top:4%;
        margin-bottom: 4%;
    }
    .input-search-bar{
        border-radius: 24px;
        height: 48px;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
        width: 88%;
        border:none;
    }
    .search-bar-button{
        font-size: 24px;
        background-color: transparent;
        border:none;
        color: black
    }

    .bi-search{
        color: black
    }
    .banner{
        height: 100%;
    }
    .dashboardLink{
        display:none;
    }
    .navbar{
        height: 8%;
    }
    .logo{
        margin-right:110px;
        margin-top:16px;
    }
     .navbar-bottom{
        padding-top:64px;
        padding-bottom: 64px;
        background-color:#E75B10;
    }
     .nav-buttons{
        position: absolute;
        margin-top: 6%;
        left: 50%;
        text-align: center;
    }
    .logoCheckout{
        width: 100%;
        text-align: center;
        margin-left:30%;
    }
    .logoCheckout-catalogo{
        width: 100%;
        text-align: center;
        margin-left:10%;
    }
    .logoCheckout-kangupago{
        width: 100%;
        text-align: center;
        margin-left:20%;
    }
    .logo_catalogo_footer{
        display: none;
    }
    .logo_kangupago_footer{
        display: none;
    }
    .logo_catalogo{
        margin-right: 48px;
        margin-top:16px;
        margin-bottom: 8px;
    }
    .logo_kangupago{
        margin-right: 92px;
        margin-top:16px;
        margin-bottom: 8px;
    }

    .floating{
        position:fixed;
        width:60px;
        height:60px;
        bottom:100px;
        right:18px;
        background-color:#25d366;
        color:white;
        border-radius:50px;
        text-align:center;
        font-size:36px;
        box-shadow: 2px 2px 3px #999;
        z-index:100;
    }
    
    .floating:hover{
        color: white;
    }
    
    .float-button{
        margin-top:16px;
        margin-left: 4px;
    }
    
    .float-button:hover{
        color:white;
    }
}

.btn-sign-up-nav{
    border-radius: 25px;
    margin-left: 16px;
}

.btn-sign-in-nav{
    border-radius: 25px;
    margin-left: 16px;
    background-color:#F2F3F7;
    color:black;
}

.btn-sign-in-nav:hover{
    border-radius: 25px;
    margin-left: 16px;
    background-color:#F2F3F7;
    color:black;
}

@media (max-width: 600px) { 
    .btn-sign-up-nav{
        border-radius: 25px;
        width: 40%;
        margin-left: 0px;
        margin-top:4px;
    }
    .btn-sign-in-nav{
        border-radius: 25px;
        width: 40%;
        margin-left: 0px;
        margin-top:4px;
    }
    .nav-links-top{
        width:100%;
        text-align:center;
    }
}

/* LANDING PAGE */
.landingPage1{
    padding-top:400px;
    padding-bottom: 150px;
}

.landingPageVendedores1{
    background-color: #F5F5F7;
    padding-top:64px;
    padding-bottom: 40px;
}


.landingPageVendedoresLogos{
    background-color: #F5F5F7;
    margin-top:64px;
    width:100%;
    text-align: center;
    margin-bottom: 64px;
}

.equipo{
    width: 96%;
    margin-top:4%;
    margin-right: 2%;
    margin-left: 2%;
}

.equipo-text{
    text-align: center;
}

.center-text{
    text-align: center;
}

/* .retoSemanalImg{

} */

.landingCatch{
    color:black;
    width: 100%;
    text-align: center;
    margin-top:-350px;
    
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 56px;
}


.LP-Products1{
    width: 80%;
    height: 60%;
    margin-left: 10%;
    margin-top:1%;
    text-align: center;
}

.btn-CTA{
    border-radius: 25px;
    margin-top:80px;
    background-color:#E75B10;
    color:white;
    position: absolute;
    margin-top:4%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 20px;
}

.landingPage2{
    padding-top:64px;
    padding-bottom: 84px;
    background-color: white;
}

.landingPage2-Nosotros{
    padding-top:40%;
    padding-bottom: 10%;
    background-color: white;
}

.landingPage2-Cobertura{
    padding-top:4%;
    padding-bottom: 10%;
    background-color: white;
}


.landingCatalogo2{
    padding-top:40%;
    padding-bottom: 26%;
}

.landingPage2-Contacto{
    padding-top:32%;
    padding-bottom: 10%;
    background-color: white;
    margin-bottom:-8%;
}

.landingPage2-Capacitacion{
    padding-top:28%;
    padding-bottom: 10%;
    background-color: white;
}



.stats-nosotros{
    width: 100%;
    background-color:#E75B10;
    padding-top: 4%;
    padding-bottom: 4%;
    color:white;
}

.stats-nosotros-text{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-align: center;
    font-size: 42px;
}

.stats-nosotros-text2{
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 24px;
}

.landingPage6{
    padding-bottom: 48px;
    background:white;
}

.landingText{
    color:black;
    text-align: left;
    font-size: 44px;
}

.landingText-Nosotros{
    color:black;
    text-align: left;
    font-size: 36px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-style: italic;
}

.landingText-Nosotros-Orange{
    color:#E75B10;
}

.landingText-Contacto{
    color:black;
    text-align: left;
    font-size: 36px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.text-nosotros{
    margin-top: 32px;
}

.landingText22{
    width: 100%;
    color:black;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 32px;
}

.landingTextProveedores{
    width: 100%;
    color:black;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 32px;
}

.landingText2-Nosotros{
    width: 100%;
    color:black;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 36px;
}

.text-p-nosotros{
    margin-top:24px;
    margin-right:10%;
    text-align: left;
    font-size: 20px;

}

.carouselTextTitle{
    color:#E75B10;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 22px;
}

.carouselTextExtra{
    color:black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 22px;
}

.carouselText{
    width: 100%;
    text-align: center;
}

.LP-Steps{
    width: 60%;
    height: 60%;
    margin-left: 20%;
    margin-top:2%;
}

.LP-Steps2{
    width: 60%;
    height: 60%;
    margin-left: 20%;
    margin-top:3%;
}

.landingPage3{
    padding-bottom: 48px;
    margin-bottom:-5%;
}

.landingPage32{
    background-color: white;
    padding-bottom: 48px;
}

.landingText2{
    color:black;
    width: 100%;
    text-align: center;
    padding-top:24px;
    
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 56px;
}

.LP-Steps3{
    margin-top:5%;
    height: 50%;
    width: 50%;
    margin-left:20%;
}

.SM-Logos{
    width: 30%;
    height: 30%;
    margin-left: 35%;
    margin-top:6%;
}

.landingCatchMarca{
    color:black;
    width: 100%;
    text-align: left;
    margin-top:-280px;
    margin-left:18%;
    
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 92px;
}

.landingTextMarca{
    color:#4A4A4A;
    width: 100%;
    text-align: left;
    
    margin-left:18%;
    
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 20px;
}

.landingCatchImgMarca{
    margin-left:30%;
    width: 300px;
    height: 600px;
    margin-top:-350px;
}

nav{
   border-bottom-right-radius: 32px; 
   border-bottom-left-radius: 32px; 
}

.landingCatchImgVendedor{
    margin-left:20%;
    width: 392px;
    height: 468px;
    margin-top: -48px;
}

.landingLogosImgVendedor{
    width: 592px;
    height: 392px;
    padding-top:4%;
    padding-bottom:3%;
    margin-bottom: 5%;

}

.logos_landing{
    margin-top: 20%;
    font-size: 36px;
    font-weight: bold;
    margin-right: 12%;
}

.proveedoresLogo{
    background-color: #E75B10;
}

.landingCatchImgVendedor2{
    margin-left:20%;
    width: 424px;
    height: 468px;
    margin-top: -16px;
}

.landingClabeImgVendedor{
    width:100%;
    position:relative;
}

.landingClabeImgVendedor5{
    width:100%;
    text-align: center;
    position:absolute;
    margin-top: 53%;
}

.landingPageCobertura{
    padding-top:65%;
    padding-bottom: 18%;
}

.landingPageCobertura2{
    padding-top:65%;
    padding-bottom: 16%;
}


.landingCatchImgNosotros{
    margin-left:24%;
    width: 392px;
    height: 392px;
    margin-top: -48px;
}

.landingCatchImgReferidos{
    margin-left:24%;
    width: 392px;
    height: 392px;
    margin-top: 12%;
}

.landingCatchImgRS{
    margin-left:22%;
    width: 648px;
    height: 600px;
    margin-top: -4%;
    padding: 64px;
    border-radius: 4%;
    background-color: #F5F5F7;
}



.landingCatchImgContacto{
    margin-left:15%;
    width: 548px;
    height: 492px;
    margin-top: -72px;
}

.landingCatchImgCapacitacion{
    margin-left:15%;
    width: 548px;
    height: 492px;
    margin-top: 0px;
}

.landingClabeImgVendedor2{
    width: 100%;
    height: 324px;
    position: absolute;
}

.landingImgCatalogoEnLinea{
    width:100%;
    position:relative;
}


.landingImgKanguPago{
    width: 100%;
    height: 524px;
}

.squareLandingImgCatalogo{
    width: 419px;
    height: 368px;
    margin-left: 16%;
    margin-top: 32%;
    position: absolute;
    z-index: 100;
    background-color: white;
    border-radius: 8px;
}

.squareLandingImgCatalogo2{
    width: 419px;
    height: 368px;
    margin-top: 32%;
    position: absolute;
    z-index: 100;
    background-color: white;
    border-radius: 8px;
}

.landingCarrouselImg{
    margin-left:25%;
    width: 344px;
    height: 484px;
}

.textClabe{
    position: absolute;
    text-align: center;
    color:white;
    z-index: 100;
    top:48px;
    font-size: 32px;
    font-weight: bold;
    left: 15%;
}

.textClabe2{
    position: absolute;
    text-align: center;
    color:white;
    z-index: 100;
    top:48px;
    left: 16%;
}

.textEnvio{
    font-size: 42px;
    font-weight: bold;
    margin-top:16px;
}

.landingClabeImgVendedor3{
    width: 272px;
    left: 40%;
    top: 124px;
    height: 564px;
    position: absolute;
    z-index: 100;
}


.btn-CTA-Marca{
    border-radius: 25px;
    margin-top:80px;
    background-color:#E75B10;
    color:white;
    position: absolute;
    margin-top:4%;
    left: 16%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 24px;
}

.pointer{
    cursor: pointer;
}

.landingPage4{
    padding-bottom: 48px;
}

.landingSubTextMarca{
    color:black;
    width: 100%;
    text-align: center;
    margin-top:32px;
    
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 52px;
}

.landingListTextMarca{
    color:black;
    width: 100%;
    margin-top:16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 24px;
}

.landingTextEnfasis{
    color:#E75B10;
}

.landingPage5{
    padding-top:64px;
    padding-bottom: 100px;
    background-color: white;

}

.landingImgCatalogoEnLinea2{
    width: 100%;
    height: 524px;
    position: absolute;
}

.center-text{
    text-align: center;
}

.terminosCondicionesCatch{
    color:black;
    width: 100%;
    text-align: center;
    margin-top:64px;
    
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 42px;
}

.enviosCatch{
    color:black;
    width: 100%;
    text-align: center;
    margin-top:64px;
    
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 36px;
}

.landingPFText{
    font-size: 18px;  
}

.textNosotros{
    position: absolute;
    color:white;
    z-index: 100;
    top:84px;
    width: 100%;
}

.textCapacitacion{
    position: absolute;
    color:white;
    z-index: 100;
    top:104px;
    width: 100%;
}

.textNosotros2{
    font-size: 42px;
    font-weight: bold;
    margin-top:16px;
    text-align: center;
}


.textCatalogo{
    position: absolute;
    color:white;
    z-index: 100;
    margin-top:72px;
    width: 54%;
}

.rectangle_catalogo{
    background-color:white;
    margin-top:4%;
    margin-left: 25%;
    height: 135px;
    width: 20px;
}

.textCatalogo2{
    font-size: 44px;
    font-weight: bold;
    margin-top:16px;
    margin-left: 2%;
    text-align: left;
}

.textCatalogo3{
    font-size: 24px;
    margin-top:4px;
    margin-left: 28%;
    text-align: left;
    width: 60%;
}

.textCatalogo4{
    color: black;
    font-size: 28px;
    margin-top:48px;
    font-weight: bold;
    text-align: center;
}

.first_sq_catalogo{
    width: 60%;
    margin-left: 20%;
    margin-top:4%;
}

.second_sq_catalogo{
    width: 60%;
    margin-left: 20%;
    margin-top:9%;
}

.name_pending_product{
    margin-top:24px;
}

.textCatalogo5{
    color: #808083;
    font-size: 15px;
    margin-top:20px;
    text-align: center;
    width: 80%;
    margin-left: 10%;
}

.textRetoSemanal{
    font-size: 42px;
    font-weight: bold;
    margin-top:16px;
    text-align: center;
    color: black;
}

.municipio{
    font-weight: bold;
}

.bold{
    font-weight: bold;
}

.terminosCondicionesEnfasis{
    color:black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.estadosCoberturaImg{
    width: 600px;
    height: 400px;
    margin-left: 28%;
    margin-top: -60px;
    margin-bottom: -60px;
}

.estadosCoberturaPCE{
    margin-left:64px;
    margin-right:64px;
}

.estadosCoberturaPCEText{
    color:black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 32px;
}

.municipiosCoberturaPCEText{
    color:black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 20px;
}

.footer{
    width: 100%;
    background-color:#E75B10;
    padding-bottom:10%;
}

.footer_kangupago{
    width: 100%;
    background-color:#00A3D5;
    padding-bottom:80px;
    margin-top:8%;
}

.footers{
    padding-top:50px;
}

.footerLink{
    text-decoration: none;
    color:white;
    margin-right:16px;
    cursor: pointer;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

.footerLink:hover{
    color:black;
}

.footer-spacing{
    padding-bottom:8%;
}

.footer-spacing-catalogos{
    padding-bottom:32%;
}

@media (max-width:1440px){
    .btn-grey-landing{
        width: 85%;
    }
    .landingClabeImgVendedor5{
        width:100%;
        text-align: center;
        position:absolute;
        margin-top: 48%;
    }
    
    .landingPageCobertura{
        padding-top:56%;
        padding-bottom: 15%;
    }
    .landingPageCobertura2{
        padding-top:65%;
        padding-bottom: 14%;
    }
    .carousel-item{
        width: 94%;
    }

    /* LANDING CATALOGO */
    .squareLandingImgCatalogo{
        width: 464px;
        height: 400px;
        margin-left: 14%;
        margin-top: 30%;
        position: absolute;
        z-index: 100;
        background-color: white;
        border-radius: 8px;
    }
    
    .squareLandingImgCatalogo2{
        width: 464px;
        height: 400px;
        margin-top: 30%;
        position: absolute;
        z-index: 100;
        background-color: white;
        border-radius: 8px;
    }


}


@media (max-width:1350px){
    .btn-CTA-Marca{
        font-size: 24px;
        left: 16%;
    }
    .btn-grey-landing{
        width: 90%;
    }
    .landingClabeImgVendedor5{
        width:100%;
        text-align: center;
        position:absolute;
        margin-top: 54%;
    }
    
    .landingPageCobertura{
        padding-top:64%;
        padding-bottom: 21%;
    }

    .landingPageCobertura2{
        padding-top:68%;
        padding-bottom: 16%;
    }

    /* LANDING CATALOGO */
    .squareLandingImgCatalogo{
        width: 419px;
        height: 368px;
        margin-left: 14%;
        margin-top: 31%;
        position: absolute;
        z-index: 100;
        background-color: white;
        border-radius: 8px;
    }
    
    .squareLandingImgCatalogo2{
        width: 419px;
        height: 368px;
        margin-top: 31%;
        position: absolute;
        z-index: 100;
        background-color: white;
        border-radius: 8px;
    }
    
}

@media (max-width:1250px){
    .landingCatchMarca{
        font-size: 80px;
    }
    
    .landingTextMarca{
        font-size: 18px;
    }
    
    .landingCatchImgMarca{
        margin-left:30%;
        width: 300px;
        height: 600px;
        margin-top:-350px;
    }

    .btn-CTA-Marca{
        font-size: 18px;
        left: 15%;
    }
    .equipo-img{
        width: 300px;
        height: 300px;
    }
    .landingCatchImgRS{
        margin-left:22%;
        width: 564px;
        height: 520px;
        margin-top: 16px;
        padding: 48px;
        border-radius: 4%;
        background-color: #F5F5F7;
    }

    /* LANDING CATALOGO */
    .squareLandingImgCatalogo{
        width: 419px;
        height: 368px;
        margin-left: 14%;
        margin-top: 36%;
        position: absolute;
        z-index: 100;
        background-color: white;
        border-radius: 8px;
    }
    
    .squareLandingImgCatalogo2{
        width: 419px;
        height: 368px;
        margin-top: 36%;
        position: absolute;
        z-index: 100;
        background-color: white;
        border-radius: 8px;
    }

    .textCatalogo2{
        font-size: 38px;
    }
    
    .textCatalogo3{
        font-size: 20px;
    }
    .landingCatalogo2{
        padding-bottom: 36%;
    }
}

@media (max-width:1150px){
    .landingCatchMarca{
        font-size: 72px;
    }
    .btn-grey-landing{
        width: 94%;
        margin-top:16px;
        font-size: 18px;
        
    }
    .landingCatchImgVendedor{
        margin-left:4%;
    }
    .landingClabeImgVendedor5{
        width:100%;
        text-align: center;
        position:absolute;
        margin-top: 60%;
        margin-left: 24px;
    }
    
    .landingPageCobertura{
        padding-top:68%;
        padding-bottom: 22%;
    }

    .equipo-img{
        width: 280px;
        height: 280px;
    }

    /* LANDING CATALOGO */
    .squareLandingImgCatalogo{
        width: 392px;
        height: 324px;
        margin-left: 14%;
        margin-top: 36%;
        position: absolute;
        z-index: 100;
        background-color: white;
        border-radius: 8px;
    }
    
    .squareLandingImgCatalogo2{
        width: 392px;
        height: 324px;
        margin-top: 36%;
        position: absolute;
        z-index: 100;
        background-color: white;
        border-radius: 8px;
    }
    .landingCatalogo2{
        padding-bottom: 40%;
    }

}

@media (max-width:1050px){
    /* LANDING CATALOGO */
    .squareLandingImgCatalogo{
        width: 364px;
        height: 312px;
        margin-left: 12%;
        margin-top: 40%;
        position: absolute;
        z-index: 100;
        background-color: white;
        border-radius: 8px;
    }
    
    .squareLandingImgCatalogo2{
        width: 364px;
        height: 312px;
        margin-top: 40%;
        position: absolute;
        z-index: 100;
        margin-left:1%;
        background-color: white;
        border-radius: 8px;
    }
    .textCatalogo2{
        font-size: 36px;
    }
    
    .textCatalogo3{
        font-size: 20px;
    }
    .landingCatalogo2{
        padding-bottom: 40%;
    }
}

@media (max-width:1000px){
    .landingCatchMarca{
        font-size: 52px;
    }
    
    .landingTextMarca{
        font-size: 14px;
    }
    .landingText{
        color:black;
        text-align: left;
        font-size: 38px;
    }

    .btn-CTA-Marca{
        font-size: 16px;
        left: 16%;
    }
    .landingCatchImgMarca{
        margin-left:30%;
        width: 200px;
        height: 400px;
        margin-top:-350px;
    }

    .landingCatchImgVendedor{
        margin-left:2%;
    }

    .landingClabeImgVendedor5{
        width:100%;
        text-align: center;
        position:absolute;
        margin-top: 72%;
        margin-left: 38px;
    }
    
    .landingPageCobertura{
        padding-top:85%;
        padding-bottom: 28%;
    
    }
    .textEnvio{
        font-size: 38px;
        font-weight: bold;
        margin-top:16px;
    }
    .textClabe2{
        position: absolute;
        text-align: center;
        color:white;
        z-index: 100;
        top:48px;
        left: 8%;
    }
    .landingCarrouselImg{
        margin-left:16%;
        width: 304px;
        height: 408px;
    }
    .landingCatchImgVendedor2{
        margin-left:10%;
        width: 364px;
        height: 402px;
        margin-top: -16px;
    }
    .carousel-item{
        width: 94%;
    }
    .landingCatchImgContacto{
        margin-left:15%;
        width: 424px;
        height: 392px;
        margin-top: -16px;
    }

    .landingCatchImgCapacitacion{
        margin-left:15%;
        width: 424px;
        height: 392px;
        margin-top: -16px;
    }

    .estadosCoberturaImg{
        width: 500px;
        height: 350px;
        margin-left: 28%;
        margin-top: -8px;
        margin-bottom: -24px;
    }

    /* LANDING CATALOGO */
    .squareLandingImgCatalogo{
        width: 364px;
        height: 312px;
        margin-left: 9%;
        margin-top: 45%;
        position: absolute;
        z-index: 100;
        background-color: white;
        border-radius: 8px;
    }
    
    .squareLandingImgCatalogo2{
        width: 364px;
        height: 312px;
        margin-top: 45%;
        position: absolute;
        z-index: 100;
        margin-left:1%;
        background-color: white;
        border-radius: 8px;
    }
    .textCatalogo2{
        font-size: 32px;
    }
    
    .textCatalogo3{
        font-size: 20px;
    }
    .landingCatalogo2{
        padding-bottom: 46%;
    }

    
}

@media (max-width:900px){
    .landingCatchMarca{
        font-size: 52px;
    }
    
    .landingTextMarca{
        font-size: 14px;
    }

    .btn-CTA-Marca{
        font-size: 16px;
        left: 16%;
    }
    .landingCatchImgMarca{
        margin-left:30%;
        width: 200px;
        height: 400px;
        margin-top:-350px;
    }
    .landingCatchImgRS{
        margin-left:10%;
        width: 564px;
        height: 520px;
        margin-top: 12%;
        padding: 48px;
        border-radius: 4%;
        background-color: #F5F5F7;
    }

    /* LANDING CATALOGO */
    .squareLandingImgCatalogo{
        width: 334px;
        height: 292px;
        margin-left: 8%;
        margin-top: 50%;
        position: absolute;
        z-index: 100;
        background-color: white;
        border-radius: 8px;
    }
    
    .squareLandingImgCatalogo2{
        width: 334px;
        height: 292px;
        margin-top: 50%;
        position: absolute;
        z-index: 100;
        margin-left:1%;
        background-color: white;
        border-radius: 8px;
    }

    .textCatalogo{
        width: 60%;
    }

    .textCatalogo2{
        font-size: 30px;
    }
    
    .textCatalogo3{
        font-size: 20px;
    }
    .landingCatalogo2{
        padding-bottom: 56%;
    }

    .rectangle_catalogo{
        height: 95px;
    }
    
}

@media (max-width:800px){
    
    .landingCatchMarca{
        font-size: 52px;
    }
    
    .landingTextMarca{
        font-size: 14px;
    }
    .landingCatchImgMarca{
        margin-left:30%;
        width: 200px;
        height: 400px;
        margin-top:-350px;
    }
    .btn-CTA-Marca{
        font-size: 16px;
        left: 18%;
    }
    .landingCatchImgVendedor{
        margin-left:2%;
        width: 372px;
        height: 432px;
        margin-top: 32px;
    }
    .btn-grey-landing{
        width: 80%;
        margin-top:16px;
        font-size: 18px;
        
    }
    .landingCatchImgVendedor2{
        display:none;
    }
    .carousel-item{
        width: 98%;
    }
    .landingCarrouselImg{
        margin-left:30%;
        width: 304px;
        height: 408px;
    }
    .textClabe{
        position: absolute;
        text-align: center;
        color:white;
        z-index: 100;
        top:48px;
        font-size: 32px;
        font-weight: bold;
        left: 8%;
        width: 90%;
    }
    .landingClabeImgVendedor3{
        width: 208px;
        left: 40%;
        top: 148px;
        height: 408px;
        position: absolute;
        z-index: 100;
    }
    .landingClabeImgVendedor5{
        width:100%;
        text-align: center;
        position:absolute;
        margin-top: 80%; 
    }
    .landingPageCobertura{
        padding-top:96%;
        padding-bottom: 30%;
    }
    .textClabe2{
        position: absolute;
        text-align: center;
        color:white;
        z-index: 100;
        top:48px;
        left: 5%;
        width: 90%;
    }
    .textEnvio{
        font-size: 32px;
        font-weight: bold;
        margin-top:12px;
    }
    .landingClabeImgVendedor2{
        width: 100%;
        height: 272px;
        position: absolute;
    }

    .landingCatchImgNosotros{
        margin-left:0%;
        width: 400px;
        height: 400px;
        margin-top: 42px;
    }

    .landingCatchImgReferidos{
        margin-left:0%;
        width: 400px;
        height: 400px;
        margin-top: 42px;
    }

    .estadosCoberturaImg{
        width: 400px;
        height: 250px;
        margin-left: 28%;
        margin-top: -8px;
        margin-bottom: -8px;
    }

    /* LANDING CATALOGO */
    .squareLandingImgCatalogo{
        width: 304px;
        height: 282px;
        margin-left: 6%;
        margin-top: 56%;
        position: absolute;
        z-index: 100;
        background-color: white;
        border-radius: 8px;
    }
    
    .squareLandingImgCatalogo2{
        width: 304px;
        height: 282px;
        margin-top: 56%;
        position: absolute;
        z-index: 100;
        margin-left:1%;
        background-color: white;
        border-radius: 8px;
    }

    .textCatalogo{
        width: 64%;
    }

    .textCatalogo2{
        font-size: 28px;
    }
    
    .textCatalogo3{
        font-size: 20px;
    }

    .textCatalogo5{
        font-size: 12px;
        margin-top: 1px;
    }

    .landingCatalogo2{
        padding-bottom: 66%;
    }

    .rectangle_catalogo{
        height: 95px;
    }
    
}

@media (max-width: 600px) { 
    nav{
        border-bottom-right-radius: 0px; 
        border-bottom-left-radius: 0px; 
     }
    .country-operation{
       display:none;
    }
    .landingPage1{
        padding-top:24px;
        padding-bottom: 92px;
    }
    .landingCatch{
        font-size: 26px;
        margin-top:0px;
        margin-bottom:10px;
    }
    .btn-CTA{
        margin-top:10%;
        font-size: 14px;
    }
    .btn-grey-landing{
        width: 94%;
        margin-top:16px;
        font-size: 18px;
        
    }
    .btn-primaryLanding{
        margin-top:16px;
        font-size: 16px;
    }
    .landingCatchImgVendedor{
        margin-left:2%;
        width: 304px;
        height: 372px;
        margin-top: 24px;
    }
    .landingCatchImgVendedor2{
        display:none;
    }
    .landingCarrouselImg{
        margin-left:13%;
        width: 304px;
        height: 408px;
    }
    .textClabe{
        position: absolute;
        text-align: center;
        color:white;
        z-index: 100;
        top:48px;
        font-size: 22px;
        font-weight: bold;
        left: 5%;
        width: 90%;
    }
    .landingClabeImgVendedor3{
        width: 192px;
        left: 26%;
        top: 124px;
        height: 372px;
        position: absolute;
        z-index: 100;
    }
    .landingClabeImgVendedor5{
        width:82%;
        text-align: center;
        position:absolute;
        margin-top: 124%; 
    }
    .landingPageCobertura{
        padding-top:148%;
        padding-bottom: 40%;
    }
    .textClabe2{
        position: absolute;
        text-align: center;
        color:white;
        z-index: 100;
        top:48px;
        left: 5%;
        width: 90%;
    }
    .landingText22{
        width: 100%;
        text-align:center;
        font-size: 28px;
    }
    .textEnvio{
        font-size: 22px;
        font-weight: bold;
        margin-top:12px;
    }
    .landingClabeImgVendedor2{
        width: 100%;
        height: 200px;
        position: absolute;
    }
    .landingLogosImgVendedor{
        width: 264px;
        height: 192px;
        padding-top:4%;
        padding-bottom:3%;
        margin-bottom: 5%;
    }
    .landingTextProveedores{
        font-size: 24px;
    }
    .carousel-item{
        width: 98%;
    }
    .landingText{
        font-size: 32px;
        margin-top:-24px;
        text-align: left;
    }
    .landingText2{
        margin-top:24px;
        font-size: 32px;
    }
    .landingCatchMarca{
        font-size: 32px;
        margin-top:16px;
    }
    .landingTextMarca{
        font-size: 12px;
        margin-top:3px;
    }
    .btn-CTA-Marca{
        margin-top:24px;
        font-size: 12px;
        margin-left:16px;
    }
    .landingCatchImgMarca{
        margin-left:30%;
        width: 110px;
        height: 220px;
        margin-top:0px;
    }
    .landingSubTextMarca{
        font-size: 32px;
    }
    .landingPage5{
        padding-top:16px;
        padding-bottom:42px;
        margin-top: 48px;
    }
    .SM-Logos{
        width: 30%;
        height: 30%;
        margin-left: 35%;
        margin-top:10%;
    }

    .footers{
        padding-top:8%;
        width: 100%;
        margin-left:4px;
    }
    
    .footerLink{
        text-decoration: none;
        color:white;
        margin-right:8px;
        font-size:14px;
        cursor: pointer;
        text-align: left;
        padding-bottom:50px;
    }

    .landingListTextMarca{
        font-size:18px;
    }

    .textNosotros{
        top:42px;
        width: 100%;
    }
    
    .textNosotros2{
        font-size: 28px;
    }

    .textRetoSemanal{
        font-size: 28px;
    }

    .landingPage2-Nosotros{
        padding-top:58%;
        padding-bottom: 10%;
        background-color: white;
    }

    .landingText-Nosotros{
        font-size: 24px;
        margin-top: 32px;
        line-height: 1.3;
    }
    .text-nosotros{
        margin-top: 32px;
        font-size: 24px;
        margin-right: 1%;
        line-height: 1.3;
    }

    .landingCatchImgNosotros{
        margin-left:0%;
        width: 292px;
        height: 292px;
        margin-top: 42px;
    }

    .landingCatchImgReferidos{
        margin-left:0%;
        width: 292px;
        height: 292px;
        margin-top: 42px;
    }

    .landingCatchImgRS{
        margin-left:-4%;
        width: 348px;
        height: 350px;
        margin-top: 24px;
        padding: 24px;
        border-radius: 4%;
        background-color: #F5F5F7;
    }

    .stats-nosotros-text{
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        text-align: center;
        font-size: 42px;
        margin-top:32px;
    }

    .estadosCoberturaImg{
        width: 350px;
        height: 216px;
        margin-left: 6%;
        margin-top: -8px;
        margin-bottom: -24px;
    }

    .municipiosCoberturaPCEText{
        font-size: 16px;
    }

    .estadosCoberturaPCE{
        margin-left:36px;
        margin-right: 36px;
    }
    
    .stats-nosotros-text2{
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        text-align: center;
        font-size: 24px;
    }

    .landingText2-Nosotros{
        margin-top: -8%;
        font-size: 32px;
    }

    .equipo-text{
        margin-bottom:32px;
    }

    .equipo-img{
        width: 324px;
        height: 284px;
    }

    .landingCatchImgContacto{
        margin-left:-14%;
        width: 324px;
        height: 292px;
        margin-top: 24px;
    }

    .landingCatchImgCapacitacion{
       display:none;
    }

    .landingPage2-Contacto{
        padding-top:50%;
        padding-bottom: 10%;
        background-color: white;
        margin-bottom:-8%;
    }

    .landingPage2-Capacitacion{
        padding-top:50%;
        padding-bottom: 50%;
        background-color: white;
        margin-bottom:-8%;
    }

    .landingText-Contacto{
        font-size: 24px;
    }

    /* LANDING CATALOGO */
    

    .landingImgCatalogoEnLinea2{
        width: 100%;
        height: 242px;
        position: absolute;
    }

    .landingImgKanguPago{
        width: 100%;
        height: 232px;
        position: absolute;
    }

    .squareLandingImgCatalogo{
        width: 304px;
        height: 256px;
        margin-left: 13%;
        margin-top: 54%;
        position: absolute;
        z-index: 100;
        background-color: white;
        border-radius: 8px;
    }

    .squareLandingImgCatalogo3{
        margin-top: 48%;
    }

    .squareLandingImgCatalogo2{
        width: 304px;
        height: 256px;
        margin-left: 13%;
        margin-top: 125%;
        position: absolute;
        z-index: 100;
        background-color: white;
        border-radius: 8px;
    }

    .squareLandingImgCatalogo4{
        margin-top: 116%;
    }
    
    .textCatalogo{
        position: absolute;
        color:white;
        z-index: 100;
        margin-top:32px;
        width: 66%;
    }
    
    .rectangle_catalogo{
        background-color:white;
        margin-top:7%;
        margin-left: 16%;
        height: 60px;
        width: 12px;
    }
    
    .textCatalogo2{
        font-size: 19px;
        font-weight: bold;
        margin-top:16px;
        margin-left: 2%;
        text-align: left;
    }

    .textCatalogo3{
        font-size: 14px;
        margin-top:4px;
        margin-left: 20%;
        text-align: left;
        width: 50%;
    }
    
    .textCatalogo4{
        font-size: 24px;
        margin-top: 24px;
    }
    .textCatalogo5{
        font-size: 12px;
    }
    .textKanguPagoFix{
       font-size: 20px; 
    }
    .landingCatalogo2{
        padding-top: 120%;
    }
}



/* HOME */

.home_img_size{
    width: 116%;
    height: 100%;
    cursor: pointer;
}

.home_img_size:hover{
    transform: scale(1.02);
}

.home_img_size2{
    width: 133%;
    height: 100%;
    cursor: pointer;
}

.home_img_size2:hover{
    transform: scale(1.02);
}

.home_img_right{
    margin-left: 16%;
}

.home_img_right2{
    margin-left: 4%;
}

.side-menu-home{
    height: 800px;
    width: 200px;
    /* border-right: 1px solid #E3E0DD; */
    background-color: white;
    border-radius: 16px;
    overflow: auto;
}

.side-menu-home::-webkit-scrollbar {
    -webkit-appearance: none;
}

.side-menu-home::-webkit-scrollbar:vertical {
    width: 6px;
    
}

.side-menu-home::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #9B9B9B;
}
  

.btnVerMas{
    margin-top: 40px;
    margin-left:40%;
    background-color: black;
    border-style: none;
    color:white;
    font-size:16px;
    border-radius: 24px;
    padding-right: 32px;
    padding-left:32px;
    padding-top:12px;
    padding-bottom:12px;
    
}

.side-menu-home-hidden{
    display: none;
}

.noProducts{
    width: 100%;
    margin-top:10%;
    text-align: center;
}

.catalogo-buttom{
    margin-bottom: 20%;
}

.ethos{
    width: 90%;
    margin-left: 0.2%;
    height: 42px;
    margin-top:24px;
    background-color:#003367;
    text-align: center;
    border-radius: 4px;
}

.ethosText{
    margin-top:3%;
    color: white;
}

.catalogo_banner{
    width: 90%;
    margin-left: 2px;
    border-radius: 4px;
    margin-top:24px;
}

.categoria_container{
    width: 91%;
    margin-left: 12px;
}

.categoria{
    width: 120px;
    height: 74px;
    font-size: 22px;
    padding-top:18px;
    background-color: #E3E0DD;
    color: black;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 16px;
    margin-right:16px;
    
}

.categoria_active{
    width: 120px;
    height: 74px;
    font-size: 22px;
    padding-top:18px;
    background-color:#EE801E;
    color: white;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 16px;
    margin-right:16px;
}

.categorias{
    margin-top: 24px;
    margin-bottom: 64px;
}

.categoria_text{
    text-align: center;
    margin-top: 64px;
    font-weight: bold;
}


.products-dashboard{
    margin-top:-32px;
}

.flow_de_compra{
    width: 90%;
    height: 230px;
}

.deTemporada{
    width: 130%;
    height: 92%;
    cursor: pointer;
}

.yellow-emphasis{
    color: black;
    background-color: #ffff00;
}


.bienvenidoKanguru{
    width: 130%;
    height: 85%;
    cursor: pointer;
}

.bienvenidoKanguru2{
    width: 130%;
    height: 92%;
}

.bienvenidoKanguruNegocios{
    width: 100%;
    height: 100%;
    cursor: pointer;
    margin-top:16px;
}

.novedades{
    width: 224%;
    height: 97%;
    margin-left:148%;
    cursor: pointer;
}

.empezarVender{
    position: relative;
}

.novedades2{
    width: 212%;
    height: 100%;
    margin-left:81%;
}

.novedadesNegocioHome{
    width: 212%;
    height: 94%;
    margin-left:146%;
    cursor: pointer;
}

.videosHome{
    width: 90%;
}

.novedades2:hover{
    transform: scale(1.01);
}

.videoCaption{
    font-weight: bold;
}

.video{
    width: 100%;
    height: 69%;
    margin-top:5%;
    border: 0.5px solid black;
}

.video-center{
    width: 400px;
    height: 224px;
    margin-top:5%;
    border: 0.5px solid black;
}

.storesHome:hover{
    transform: scale(1.02);
}

.productsHome{
    margin-top: -16px;
}

.contentText{
    color:black;
    width: 100%;
    text-align: left;
    
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 36px;
}

.contentTextTiendas{
    color:black;
    width: 100%;
    text-align: left;
    margin-bottom: -16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 36px;
}

@media (max-width: 600px) {
    .agrega-productos{
        padding-bottom: 200px;
    }
    .side-menu-home{
        display:none;
    }
    .deTemporada{
        width: 100%;
        height: 100%;
    }
    .bienvenidoKanguru{
        width: 100%;
        height: 100%;
        cursor: pointer;
        margin-top:16px;
    }
    .bienvenidoKanguru2{
        width: 100%;
        height: 100%;
        margin-top:16px;
    }
    .bienvenidoKanguruNegocios{
        width: 94%;
        height: 82%;
        cursor: pointer;
        margin-top:32px;
    }
    .novedades2{
        display: none;
    }
    .novedadesNegocioHome{
        display: none;
    }
    .novedades{
        display: none;
    }
    .contentText{
        width: 100%;
        text-align: left;
        font-size: 24px;
        margin-top:16px;
        margin-bottom:-16px;
    }
    .contentTextTiendas{
        width: 100%;
        text-align: left;
        font-size: 24px;
        margin-top:16px;
        margin-bottom:-16px;
    }

    .video{
        width: 100%;
        height: 100%;
        margin-top:5%;
        border: 0.5px solid black;
    }

    .video-center{
        width: 324px;
        height: 164px;
    }

    .home_img_size{
        width: 101%;
        height: 100%;
        cursor: pointer;
    }
    
    .home_img_size2{
        width: 102%;
        height: 100%;
        cursor: pointer;
    }

    .home_img_right{
        margin-left: 2%;
    }
    
    .home_img_right2{
        margin-left: 0%;
    }

    .noDispHomeMobile{
        display: none;
    }
    .ethosText{
        margin-top:14%;
        font-size: 14px;
    }
    .catalogo_banner{
        width: 90%;
        height: 148px;
        margin-left: 2px;
        border-radius: 4px;
        margin-top:24px;
    }
    .categorias{
        margin-top: 24px;
        margin-bottom: 64px;
    }

    .videoSpacing{
        margin-bottom: 4rem;
    }
    
    .categoria_text{
        text-align: center;
        margin-top: 48px;
        font-size: 16px;
    }
    .categoria{
        height: 46px;
        font-size: 11px;
        padding-top:16px;
        border-radius: 4px;
        text-align: center;
        cursor: pointer;
        margin-bottom: 16px;
        margin-right:8px;
        
    }
    .categoria_active{
        background-color:#EE801E;
    }
    .categoria_container{
        margin-bottom: -20%;
    }
    .noProducts{
        width: 100%;
        margin-top:30%;
        text-align: center;
    }
    .flow_de_compra{
        width: 100%;
        height: 116px;
    }
}

@media (min-width: 600px) {
    .bienvenidoKanguruNegocios{
        display:none;
    }
}

@media (min-width: 700px) {
    .side-menu-home{
        height: 390px;
        width: 232px;
        /* border-right: 1px solid #E3E0DD; */
    }
}

@media (min-width: 1000px) {
    .side-menu-home{
        height: 522px;
        width: 232px;
        /* border-right: 1px solid #E3E0DD; */
    }
}

.side-menu-filter-title{
    margin-top: 8px;
    font-size: 24px;
    font-weight: bold;
    color:black;
}
.side-menu-home-text{
    margin-top: 8px;
    font-size: 18px;
    /* font-weight: bold; */
    color:black;
    cursor: pointer;
    text-decoration: none;
}

.addProductOption{
    cursor: pointer;
}

.side-menu-home-text:hover{
    color:#E75B10;
}

.btnVerMas{
    margin-top: 40px;
    margin-left:40%;
    background-color: black;
    border-style: none;
    color:white;
    font-size:16px;
    border-radius: 24px;
    padding-right: 32px;
    padding-left:32px;
    padding-top:12px;
    padding-bottom:12px;
    
}

/* CARDS */
.estrellasCard{
    margin-top: -8px;
    margin-bottom: 16px;
}

.imageSizeCardDesktop{
    height: 300px;
}

.imageSizeCardDesktopSoldOut{
    height: 300px;
    opacity: 0.3;
}

.soldOut{
    transition: .5s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.card-img-top:hover{
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2);
}

.noHover{
    pointer-events: none;
}

.homeContent{
    margin-top:-32px;
}


/* // Card Sizes in Medium devices (tablets, 768px and up) */
@media(min-width: 360px){
    .card-catalogo .imageSizeCardDesktop{
        height: 10rem;
        border-radius: 0.5rem;
    }
    .card-catalogo .imageSizeCardDesktopSoldOut{
        height: 10rem;
    }

    .card-catalogo .imageSizeCardDesktopLogo{
        height: 10rem;
        width: 10rem;
        border-radius: 0.5rem;
    }
}
@media(min-width: 380px){
    .card-catalogo .imageSizeCardDesktop{
        height: 11rem;
        border-radius: 0.5rem;
    }
    .card-catalogo .imageSizeCardDesktopSoldOut{
        height: 11rem;
    }
    .card-catalogo .imageSizeCardDesktopLogo{
        height: 11rem;
        width: 11rem;
        border-radius: 0.5rem;
    }
}
@media (max-width: 600px) {
    .imageSizeCardDesktop{
        height: 20rem;
    }
    
    .imageSizeCardDesktopSoldOut{
        height: 20rem;
    }

    
    .card{
        width: 100%;
        height: 26rem;
        margin-bottom:-16px;
        background-color: transparent;
        border:transparent;
    }
    .card-catalogo .card{
        width: 116%;
        height: 18rem;
        margin-bottom:-24px;
        background-color: transparent;
        border:transparent;
    }
    .side-menu-home-text{
        font-size: 16px;
    }
    .catalogoMobile{
        margin-bottom:32%;
    }
    
}

@media (min-width: 700px) {
    .imageSizeCardDesktop{
        height: 16rem;
    }
    .imageSizeCardDesktopSoldOut{
        height: 16rem;
    }
    .card{
        width: 16rem;
        height: 20rem;
        margin-left: 4px;
        background-color: transparent;
        border:transparent;
    }
}

/* // Card Sizes in Normal desktops */
@media (min-width: 1200px) {
    .imageSizeCardDesktop{
        height: 17rem;
    }
    .imageSizeCardDesktopSoldOut{
        height: 17rem;
    }
    .card{
        width: 17rem;
        height: 22.5rem;
        margin-left: 4px;
        background-color: transparent;
        border:transparent;
    }
}


/* // Card Sizes in Very Large devices (desktops, 992px and up) */
@media (min-width: 1400px) {
    .imageSizeCardDesktop{
        height: 19rem;
    }
    .imageSizeCardDesktopSoldOut{
        height: 19rem;
    }
    .card{
        width: 18rem;
        height: 23rem;
        background-color: transparent;
        border:transparent;
    }
}

.card-title{
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;     /* fallback */
    max-height: 32px;      /* fallback */
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.comision{
    color:#E75B10;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    
}

.product-price{
    font-weight: bold;
    font-size: 20px;
    color:black;
    font-family: 'Open Sans', sans-serif;
}

.product-price-catalogo{
    font-size: 18px;
    color:#808083;
    font-family: 'Open Sans', sans-serif;
}


/* SIGN UP  */
.sign-up-title{
    width: 100%;
    text-align:center;
    margin-top:32px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 48px;
    color:#E75B10;
}

.eresMarca{
    font-size: 18px;
}

.signUpForm{
    background-color:white;
    padding-top:48px;
    padding-bottom:48px;
    border-radius: 16px;
    margin-top: 28%;
    width: 500px;
}

.signUpForm1{
    background-color:white;
    padding-top:48px;
    padding-bottom:48px;
    border-radius: 16px;
    margin-top: 28%;
    width: 500px;
}


.signUpTitle{
    font-weight: bold;
}

.buttonSignUp{
    height: 40px;
    width: 150px;
}

.dividerSignUpVendedor{
    width: 400px;
    margin-top: 16px;
}

/* SIGN IN */
.sign-in-title{
    width: 100%;
    text-align:center;
    margin-top:48px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 48px;
    color:#E75B10;
}


.signInForm{
    background-color:white;
    padding-top:48px;
    padding-bottom:48px;
    border-radius: 16px;
    margin-top: 22%;
    width: 500px;
}

.recuperarContraseña{
    background-color:white;
    padding-top:48px;
    padding-bottom:48px;
    border-radius: 16px;
    margin-top: 16%;
    width: 500px;
}

.signIn-error{
    color:#B12704;
    font-size: 12px;
}

@media (max-width: 600px) {
    .signIn-error{
        color:#B12704;
        font-size: 12px;
    }
    .signInForm{
        margin-top: 264px;
        width: 350px;
        margin-left:2px;
    }
    .recuperarContraseña{
        margin-top: 200px;
        width: 350px;
        margin-left:2px;
    }
    .signUpForm{
        margin-top: 392px;
        margin-bottom: 20px;
        width: 350px;
        margin-left:2px;
    }
    .signUpForm1{
        margin-top: 424px;
        margin-bottom: 20px;
        width: 350px;
        margin-left:2px;
    }
}

/* CUENTA VENDEDOR */
.cuentaContenido{
    margin-left: 24px;
}

.logout{
    display: none;
}

@media (max-width: 600px) {
    .cuentaContenido{
        margin-left: 16px;
    }

    .logout{
        display: inline;
        text-align: right;
        font-size: 24px;
        font-weight: bold;
        margin-right: 16px;
    }
    .iconText{
        font-size:12px;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
    .cuentaContenido{
        margin-left: 48px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .cuentaContenido{
        margin-left: 170px;
    }
}

/* Medium-Large devices (desktops, 992px and up) */
@media (min-width: 1000px) {
    .cuentaContenido{
        margin-left: 230px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 1200px) {
    .cuentaContenido{
        margin-left: 320px;
    }
}

.listaVentas{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 768px) {
    .listaVentas{
        width: 50%;
    }
}

.comisionVenta{
    color: #00D650;
    margin-top: 16px;
    margin-left:20%;
}

.retiro{
    color: #e40b00;
    margin-left:40%;
}

.comisionVentaNoPagada{
    color:#7c7c7c;
    margin-top: 16px;
    margin-left:20%;
}

.comisonVentaCancelada{
    color: #e40b00;
    margin-top: 16px;
    margin-left:20%;
}

.bottomInfoComision{
    color:#7c7c7c;
    font-size: 15px;
    margin-top: 2%;
}

.iconPerfil{
    font-size: 136px;
}

.iconTrash{
    font-size: 24px;
    color: #7c7c7c;
    cursor: pointer;
}

.iconTrash:hover{
    color: #e40b00;
}


.infoCuenta{
    margin-top: 38px;
    margin-left: 16px;
}

.dineroCuenta{
    height: 36px;
    width: 113px;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    border-radius: 64px;
    pointer-events: none;
}

.dineroCuenta:hover{
    background-color: #42577A;
}

.retirarDinero{
    height: 48px;
    width: 150px;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    border-radius: 64px;
    margin-bottom: 16px;
}

.ajustesIcon{
    font-size: 40px;
}

.ajustesIconColor{
    color: black;
}

.ajustesIconColor:hover{
    color: black;
}

.nombreVendedor{
    font-weight: bold;
    font-size: 24px;
}

/* PRODUCT DETAILS */
.productImages{
    width: 72px;
    height: 72px;
    margin-bottom: 16px;
    border-radius: 8px;
    /* border: 1px solid black; */
    cursor: pointer;
}
.productImageActive{
    height: 100%;
    width: 100%;
    border-radius: 8px;
    max-height: 580px;
    transition: .5s ease;
    backface-visibility: hidden;
    /* border: 1px solid black; */
}

.editIcon{
    font-size: 24px;
}

.chatBusinessLogoContainer{
    height: 72px;
    width: 72px;
    border-radius: 50%;
}

.chatBusinessTitle{
    font-size: 18px;
    font-weight: bold;
}

@media (max-width: 600px) {
    .productImages{
        width: 56px;
        height: 56px;
        margin-right: 16px;
        border-radius: 8px;
    }
    /* .productImagesMobile{
        width: 32px;
        height: 32px;
        margin-right: 16px;
        border-radius: 4px;
    } */
    .productImageActive{
        height: 100%;
        width: 94%;
        border-radius: 8px;
    }
    .iconPerfil{
        display: none;
    }

    .infoComisiones{
        display: none;
    }

}

.productInfo{
    margin-top: 24px;
    margin-left: 16px;
}

.orderInfo{
    border: 1px solid #E3E0DD;
    border-radius: 8px;
    margin-left: 16px;
    height: 100%;
}

.storeName{
    color: #2183E6;
    font-weight: bold;
}

.socialProof1{
    color: #808083;
}

.bi-star-fill{
    color:black;
}

.btn-tag{
    background-color: #00A650;
    color: white;
    border-style: none;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.storeLink{
    text-decoration: none;
}

.linkToPage{
    cursor: pointer;
    text-decoration: none;
    color:#2183E6;
}

.blueUnderline{
    cursor: pointer;
    text-decoration: underline;
    color:#2183E6;
}

.download-csv{
    color:#2183E6;
    cursor: pointer;
}

.underline{
    text-decoration: underline; 
}

.linkToPage:hover{
    text-decoration: underline;
    color:#2183E6;
}

.storeLink:hover{
    text-decoration: underline;
}

.rating{
    margin-top: -12px;
}

.productName{
    font-weight: bold;
}


.calificacionPromedio{
    font-size: 64px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color:#2C589E;
    margin-left: 54%;
    /* color: black; */
}

.calificacionPromedioKanguPago{
    font-size: 64px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    margin-left: 54%;
    color: black;
}

.estrellasPromedio{
    margin-left: 22%;
    font-size: 22px;
    margin-top: -24px;
}

.reseñasPromedio{
    color:#808083;
    margin-top:8px;
    font-size: 14px;
    /* margin-left: 24%; */
    width: 100%;
}

.productPrice{
    color:black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

.productPriceCheckout{
    color:black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size:32px;
}

.productStatus{
    color: #00A650;
}

.paymentIcons{
    width: 16%;
    height: 12%;
}

.comisionProduct{
    font-weight: bold;
    pointer-events: none;
}

.productDetails{
    font-weight: bold;
}

.productDetailsPoints{
    margin-bottom: -5px;
}

.material-titulo{
    font-weight: bold;
}

.linkProduct{
    word-wrap:break-word;
}

/* TEMPLATES */
.template-name{
    font-weight: bold;
    font-size: 20px;
}

.templateImg{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    cursor: pointer;
}

.templatesList{
    width: 90%;
}

.templateImg:hover{
    width: 101%;
    height: 101%;
    transition: .3s ease;
    backface-visibility: hidden;
}



/* CATALOGO */
.catalogoTitle{
    color:black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 28px;
}

.catalogosTitle{
    color:black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 28px;
}

.catalogoList{
    width: 80%;
}

.edit-icon{
    margin-left: 84%;
    font-size: 24px;
    cursor: pointer;
}

.trash-icon{
    margin-left: 4%;
    font-size: 24px;
    cursor: pointer; 
}

.trash-icon:hover{
    color: #e40b00;
}

.catalogo{
    background-color: white;
    border-radius: 8px;
    height: 205px;
    border: 1px solid #E3E0DD;
}

.dropdown-item{
    cursor: pointer;
    font-size: 16px;
}

.catalogo-nombre{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.imgCatalogo{
    width: 100%;
    height: 100%;
}

.infoCatalogo{
    margin-top: 18px;
    margin-left: 24px;
}


.btn-compartir{
    color: black;
    border: 1px solid #E3E0DD;
    background-color: none;
    border-radius: 32px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
}

.btn-compartir:hover{
    color: white;
    border: 1px solid #E75B10;
    background-color: #E75B10;
}

.btn-compartir2{
    color: white;
    border: 1px solid #E75B10;
    background-color: #E75B10;
    margin-top: -4px;
    border-radius: 32px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
}

.btn-compartir2:hover{
    color: white;
}



@media (min-width: 350px){
    .catalogosTitle{
        color:black;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 22px;
    }
    .catalogo{
        background-color: white;
        border-radius: 8px;
        height: 140px;
        border: 1px solid #E3E0DD;
    }
}

@media(min-width: 380px){
    .catalogosTitle{
        color:black;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 28px;
    }
    .catalogo{
        background-color: white;
        border-radius: 8px;
        height: 148px;
        border: 1px solid #E3E0DD;
    }
}

@media (max-width: 600px) {
    
    .catalogoList{
        width: 100%;
    }

    .edit-icon{
        margin-left: 64%;
        font-size: 18px;
    }

    .trash-icon{
        margin-left: 16%;
        font-size: 18px;
    }

    .infoCatalogo{
        margin-top: 16px;
        margin-left: 24px;
        margin-right: 24px;
    }

    .btn-compartir{
        color: black;
        border: 1px solid #E3E0DD;
        background-color: none;
        border-radius: 32px;
        font-size: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media(min-width: 600px){
    .catalogosTitle{
        color:black;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 28px;
    }
    
    .catalogo{
        background-color: white;
        border-radius: 8px;
        height: 205px;
        border: 1px solid #E3E0DD;
    }
}




/* RESEÑAS */
.reseñasList{
    width: 100%;
    margin-top: 24px;
}

.modalReseñas .reseñasList{
    width: 100%;
    margin-top: 8px;
}

.imagenContainer{
    height: 92px;
    width: 92px;
    margin-left: 40%;
}

.resenaImagen{
    height: 100%;
    width: 100%;
    border-radius: 8px;
    transition: .5s ease;
    backface-visibility: hidden;
}

.downloadIconResena{
    color: black;
    font-size: 24px;
    cursor: pointer;
}

.estrella{
    color: #E75B10;
}

.estrella-kangupago{
    color: #00A3D5
}

.reseñaTitulo{
    margin-top:8px;
    font-size: 16px;
    font-weight: bold;
}

.reseñaDescripcion{
    margin-top:8px;
}

.reseñaFecha{
    font-size: 16px;
    margin-top: -8px;
    color: #808083;
}

.btn-reseñaMas{
    background-color: #E75B10;
    border-style: none;
    font-weight: bold;
    color:white;
    font-size:16px;
    border: 1px solid #E75B10;
}

.btn-reseñaMas:hover{
    color:#E75B10;
    background-color: white;
    border: 1px solid #E75B10;
}



/* PRODUCT GRAFIC MATERIAL */
.productGraficMaterial{
    margin-top: -8px;
}

.graficMaterial{
    position: relative;
    width: 100%;
}

.imageProductGraficMaterial{
    /* border: 1px solid black; */
    border-radius: 8px;
    height: 100%;
    transition: .5s ease;
    backface-visibility: hidden;
}

.downloadGraficMaterial{
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}


.graficMaterial:hover .imageProductGraficMaterial{
    opacity: 0.3;
}
  
.graficMaterial:hover .downloadGraficMaterial{
    opacity: 1;
}

.downloadIcon{
    color: black;
    font-size: 32px;
    cursor: pointer;
}

/* STORE DE NEGOCIO */
.logo-shape{
    background-color: black;
    border-radius: 5px;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    color: white;
}

.storeImage{
    width: 150px;
    height: 150px;
}

.storeImages{
    width: 92%;
}


.storeInfoMobile{
    margin-top: 0px;
}

@media (max-width: 500px) {
    .storeInfoMobile{
        margin-top: 48px;
    }
    .storeImages{
        width: 100%;
    }
    .reseñasList{
        margin-top: 16px;
        width: 92%;
    }
    .imagenContainer{
        height: 92px;
        width: 92px;
        margin-left: 0%;
    }
}


@media (min-width: 1000px) {
    .storeImage{
        width: 220px;
        height: 220px;
    }
}

@media (min-width: 1200px) {
    .storeImage{
        width: 264px;
        height: 264px;
    }
}
@media (min-width: 1370px) {
    .storeImage{
        width: 300px;
        height: 300px;
    }
}

.storeProducts{
    font-weight: bold;
    margin-bottom:-24px;
}

.storeNameLogo{
    font-weight: bold;
}

.ratingStore{
    margin-top: -5px;
}

/* CUENTA NEGOCIO */
.side-menu{
    height: 800px;
    width: 200px;
    border-right: 1px solid #E3E0DD;
}

@media (max-width: 600px) {
    .side-menu{
        display:none;
    }
}


@media (min-width: 1000px) {
    .side-menu{
        height: 800px;
        width: 150px;
        border-right: 1px solid #E3E0DD;
    }
}

@media (min-width: 1200px) {
    .side-menu{
        height: 800px;
        width: 150px;
        border-right: 1px solid #E3E0DD;
    }
}

@media (min-width: 1370px) {
    .side-menu{
        height: 800px;
        width: 160px;
        border-right: 1px solid #E3E0DD;
    }
}

.no-display{
    display: none;
}

.no-pointer{
    pointer-events: none;
}

.side-menu-text{
    margin-top: 16px;
    font-size: 24px;
    font-weight: bold;
    color:black;
    cursor: pointer;
    text-decoration: none;
}

.side-menu-text:hover{
    color:#E75B10;
}

.activeLinkCuentaNegocio{
    color:#E75B10;
}

.ajustesIconCuentaNegocio{
    font-size: 32px;
}

.ajustesIconColorCuentaNegocio{
    color: black;
}

.ajustesIconColorCuentaNegocio:hover{
    color: #FA243C;
}

.ventaListContentText{
    font-weight: bold;
    color:black;
    cursor: pointer;
    text-decoration: none;
}

.ventaListContentText:hover{
    color: #E75B10;
}

/* ADD PRODUCT */
.addProductFormLabel{
    font-weight: bold;
}

.addIconVariacion{
    color: white;
    font-weight: bold;
    font-size: 18px;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 8px;
    border-radius: 5px;
    background-color: #E75B10;

}

.addIcon2Variacion{
    color: #e40b00;
    background-color: white;
    font-weight: bold;
    font-size: 24px;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 8px;
    border-radius: 5px;
}

.addIcon3Variacion{
    color: #e40b00;
    background-color: white;
    font-weight: bold;
    font-size: 24px;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 8px;
    border-radius: 5px;
    margin-left: 20%;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: green!important;
}

.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 255, 0, 0.25)
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25)
}
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
    background-color: #C8FFC8; 
}

/* CUENTA VENDEDOR */
.modal-title{
    font-weight: bold;
}

.cuentaVendedorLabelForm{
    font-weight: bold;
}

.cuentaVendedorSelect{
    width: 34%;
}

/* SPINNER */
.spinner{
    width: 100%;
    text-align:center;
    margin-bottom: 20%;
    margin-top:20%;
}

.spinner-color{
    color:#E75B10;
}

.imageSizeCardDesktopLogo{
    width: 200px;
    height: 200px; 
    border-radius: 5px;
}

.store-logos{
    margin-bottom:-65px;
}

@media (max-width:600px){
    .store-logos{
        margin-bottom:0px;
    }
    .addIcon3Variacion{
        color: #e40b00;
        background-color: white;
        font-weight: bold;
        font-size: 24px;
        padding-left: 8px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 8px;
        border-radius: 5px;
        margin-left: 36%;
    }
    .cuentaVendedorSelect{
        width: 58%;
    }
    .spinner{
        width: 100%;
        text-align:center;
        margin-bottom: 80%;
        margin-top:70%;
    }

    .store-list{
        margin-top: 14%;
    }

    .imageSizeCardDesktopLogo{
        height: 11rem;
        width: 11rem;
        border-radius: 0.5rem;
    }
}

/* STRIPE CONNECT */
.stripeConnect{
    width: 100%;
    text-align: center;
    margin-top: 5%;
}

.bankIcon{
    font-size: 48px;
}

.stripeConnectTitle{
    color:black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 32px;
}


.stripeConnectDescription{
    color:black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
}





