.onboarding-container{
    background-color: white;
    width: 94%;
    margin-left: 12px;
    margin-top: 32px;
    height: 90vh;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    min-height: fill-available;
    min-height: -webkit-fill-available;
    position: relative;

}

html{
    height: -webkit-fill-available;
    height: -webkit-fill-available;
}

.exit-icon{
    position: absolute;
    top: 32px;
    right: 32px;
    font-size: 24px;
}

.onboarding-message-container{
    top: 52%;
    right: 32px;
    left: 32px;
    position: absolute;
    text-align: center;
}

.onboarding-message-main{
    font-size: 32px;
    font-weight: 700;
}

.onboarding-img-container{
    position: absolute;
    top: 8%;
    left: 48px;
    text-align: center;
}

.onboarding-img{
    width: 300px;
    height: 300px;
}

.onboarding-btn-container{
    top: 82%;
    position: absolute;
    left: 16%;
}

.btn-onboarding{
    padding-left: 92px;
    padding-right: 92px;
}

.onboarding-dots-container{
    top: 92%;
    position: absolute;
    left: 40%;
    text-align: center;
}

.onboarding-active-dot{
    color: #E75B10;
}

.onboarding-inactive-dot{
    color: #E3E0DD;
}

@media (max-width: 393px){

    .onboarding-img-container{
        position: absolute;
        top: 8%;
        left: 38px;
        text-align: center;
    }
    

    .onboarding-message-container{
        top: 52%;
        right: 32px;
        left: 32px;
        position: absolute;
        text-align: center;
    }
    
    .onboarding-message-main{
        font-size: 28px;
        font-weight: 700;
    }
}

@media (max-width: 375px){

    .onboarding-container{
        height: 88vh;
    }

    .exit-icon{
        position: absolute;
        top: 32px;
        right: 32px;
        font-size: 18px;
    }

    .onboarding-img-container{
        position: absolute;
        top: 8%;
        left: 64px;
        text-align: center;
    }
    
    .onboarding-img{
        width: 232px;
        height: 232px;
    }

    .onboarding-message-container{
        top: 52%;
        right: 32px;
        left: 32px;
        position: absolute;
        text-align: center;
    }
    
    .onboarding-message-main{
        font-size: 23px;
        font-weight: 700;
    }

    .onboarding-message-text{
        font-size: 13px;
    }

    .onboarding-btn-container{
        top: 82%;
        position: absolute;
        left: 12%;
    }

    .onboarding-dots-container{
        top: 92%;
        position: absolute;
        left: 39%;
        text-align: center;
        font-size: 12px;
    }
    
}

@media (max-width: 360px){

    .exit-icon{
        position: absolute;
        top: 32px;
        right: 32px;
        font-size: 18px;
    }

    .onboarding-img-container{
        position: absolute;
        top: 10%;
        left: 40px;
        text-align: center;
    }
    
    .onboarding-img{
        width: 264px;
        height: 264px;

    }

    .onboarding-message-container{
        top: 54%;
        right: 32px;
        left: 32px;
        position: absolute;
        text-align: center;
    }
}