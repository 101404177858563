.ver-mas-parent-home{
    margin-top:48px;
    text-align: center;
}

.btn-ver-mas{
    color:white;
    background-color: #00A3D5;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding-left:32px;
    padding-right:32px;
    padding-top:14px;
    padding-bottom:14px;
    border-radius: 32px;
}

.btn-ver-mas:hover{
    color:white;
    font-size: 18px;
    transition: 0.1s;
}

.btn-filtro{
    color:black;
    /* background-color: #E75B10; */
    background-color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    padding-left:16px;
    padding-right:16px;
    padding-top:12px;
    padding-bottom:12px;
    border-radius: 12px;
    border: 2px solid black;
}

.sin-resultados-btn{
    margin-top: 32px;
}

.btn-filtro:hover{
    color:black;
}

.sin-resultados{
    margin-top:16%;
    width: 100%;
    text-align: center;
}

.search-mobile{
    padding-bottom:32%;
}

.floating{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
    background-color:#25d366;
	color:white;
	border-radius:50px;
	text-align:center;
    font-size:36px;
	box-shadow: 2px 2px 3px #999;
    z-index:100;
}

.floating:hover{
	color: white;
}

.float-button{
    margin-top:16px;
    margin-left: 4px;
}

.float-button:hover{
    color:white;
}

@media (max-width: 1110px){
    .sin-resultados{
        margin-left: 0%;
        width: 90%;
    }
}

@media (max-width: 600px){
    .sin-resultados{
        margin-left: 4%;
        margin-top:30%;
    }

    .floating{
        position:fixed;
        width:60px;
        height:60px;
        bottom:100px;
        right:18px;
        background-color:#25d366;
        color:white;
        border-radius:50px;
        text-align:center;
        font-size:36px;
        box-shadow: 2px 2px 3px #999;
        z-index:100;
    }
    
    .floating:hover{
        color: white;
    }
    
    .float-button{
        margin-top:16px;
        margin-left: 4px;
    }
    
    .float-button:hover{
        color:white;
    }
}