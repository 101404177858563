.chat-summary-container{
    height: 48px;
    margin-left: 16px;
}

.chat-summary-title{
    font-size: 16px;
    padding-top: 8px;
    
}

.chat-summary-icon{
    font-size: 32px;
}

.chatLogo{
    height: 40px;
    width: 40px;
    border-radius: 50%;
}