.chatHeaderContainer{
    height: 48px;
    background-color: white;
    padding-left: 16px;
    color: black;
    
}

.header-title{
    margin-top: 1%;
    font-size: 22px;
    font-weight: bold;
    margin-left: 1%;
}

.chatLogoHeader{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-top: 4px;
}

.goBack{
    margin-right: 4%;
    margin-top: 8px;
}

.goBackIcon{
    font-size: 32px;
    font-weight: bold;
}

@media (max-width: 600px){
    .chatHeaderContainer{
        height: 64px;
    }
    .chatLogoHeader{
        height: 50px;
        width: 50px;
        border-radius: 50%;
        margin-top: 7px;
    }
    .header-title{
        margin-top: 5%;
        font-size: 18px;
        font-weight: bold;
        margin-left: 0%;
    }
}