
.modal-header{
    height: 64px;
}

.modal-title{
    text-align: center;
}

@media (min-width: 768px) {
    .modal-xl {
      width: 90%;
      max-width:800px;
    }
}

.btn-ciudad{
    color:black;
    background-color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    padding-left:18px;
    padding-right:18px;
    padding-top:12px;
    padding-bottom:12px;
    border-radius: 24px;
    border: 1px solid black;
}

.bold{
    font-weight: bold;
}

.sub-titulo{
   color: black; 
   font-size: 24px;
}

.descripcion{
    color: #9B9B9B;
    font-size: 16px;
    margin-top: -1.5%;
}

.divider{
    height: 1px;
    width: 98%;
    margin-left: 0%;
    background-color: #E3E0DD;
}

.btn-submit{
    background-color: #E75B10;
    font-weight: bold;
    color: white;
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-style: none;
    border-radius: 6px;
    font-weight: bold;
}

.btn-grey{
    background-color: #9B9B9B;
    color: white;
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-style: none;
    border-radius: 6px;
    font-weight: bold;
}